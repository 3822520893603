import {
  Button,
  Checkbox,
  Form,
  Input,
  Select,
  Col,
  Space,
  Row
} from 'antd'
import { useEffect, useState } from 'react'
import http from '../Services/HttpService'
import showNotification from '../Components/Notifications/Notification'
import { SelectWithAllCountries } from '../Components/SelectWithAllCountries'
import { REACT_APP_DEVICE_NAME } from '../config'
import useAuth from '../Hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoadingComponent from '../System/Components/LoadingComponent'
import useDeviceType from '../Hooks/useDeviceType'
import useLanguage from '../Hooks/useLanguage'
import CountriesRepository from '../Shared/Countries/CountriesRepository'
import ReactGA from 'react-ga4'

export default function AuthRegisterForm () {
  const [form] = Form.useForm()
  const [buttonLoading, setButtonLoading] = useState(false)
  const [prices, setPrices] = useState([])
  const [initialValues, setInitialValues] = useState({})
  const [loading, setLoading] = useState(true)
  const { deviceType } = useDeviceType()
  const { setToken } = useAuth()
  const navigate = useNavigate()
  const {t} = useTranslation(["signin"]);
  const [hasCoupon, setHasCoupon] = useState(false)
  const { getLanguage } = useLanguage()
  let language = getLanguage()

  const conversionRegisterEvent = async () => {
    console.log('new subscription')
    ReactGA.event({
      category: "ads_conversion",
      action: "ads_conversion",
      label: "New Subscription", // optional
    });
  }

  const onFinish = (values) => {
    values['device_name'] = REACT_APP_DEVICE_NAME
    values['phone_number'] = values['country_code'] + values['phone_number']

    setButtonLoading(true)

    http.post('/auth/register', values)
      .then(
        async response => {
        const data = response.data
        setToken(data.token)

        await conversionRegisterEvent()
        navigate('/system/preparing')
      })
      .catch(function (error) {
        const data = error.response.data
        showNotification({
          type: 'server',
          subtype: 'error',
          message: `Hey, something wrong happened`, //todo: translations
          description: data.message,
        })

      setButtonLoading(false)
    })
  }

  const handleCountryCode = (id) => {
    form.setFieldValue('country_code', CountriesRepository.getCountryCodeById(id))
  }

  useEffect(() => {
    let url = 'prices';
    if (language === 'pt-BR') {
      url += '?locale=pt-BR'
    }
    http.get(url).then(response => {
      let prices = response.data

      if (language !== 'pt-BR') {
        prices.shift()
      }

      setPrices(prices)

      setInitialValues({
        price_id: response.data[1].id,
        country_code: '+'
      })
      setLoading(false)
    }).catch(function (error) {
      const data = error.response.data
      showNotification({
        type: 'server',
        subtype: 'error',
        message: `Unexpected behaviour`, //todo: translations
        description: data.message,
      })
    })
  }, [language])

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <Form
      form={form}
      name="register"
      onFinish={onFinish}
      layout="vertical"
      initialValues={initialValues}
      scrollToFirstError
      style={{ padding: 0 }}
    >
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}
      >
        { deviceType !== 'ios' ? (
          <Col span={24}>

            { /* Select the plan */}
            <Form.Item
              name="price_id"
              label={ t("signin:plan") }
              rules={[
                {
                  required: true,
                  message: 'Please select your plan',
                },
              ]}
            >
              <Select
                size="large"
                placeholder={ t("signin:plan") }
              >
                {prices.map(item => (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        ) : null }

      { /* Select the country */}

      <Col span={24}>
        <SelectWithAllCountries
          label={ t("signin:country") }
          changeFunction={(e) => handleCountryCode(e)}
        />
      </Col>


        <Col span={6} xs={24} md={12}>
          { /* First name */}
          <Form.Item
            name="first_name"
            label={ t("signin:firstName") }
            rules={[
              {
                required: true,
                message: 'Please input your first name',
              },
            ]}
            validateTrigger={'onBlur'}
          >
            <Input size="large"
            />
          </Form.Item>
        </Col>

        <Col span={6} xs={24} md={12}>
          { /* Last name */}
          <Form.Item
            name="last_name"
            label={ t("signin:lastName") }
            rules={[
              {
                required: true,
                message: 'Please input your last name',
              },
            ]}
            validateTrigger={'onBlur'}
          >
            <Input size="large"
            />
          </Form.Item>
        </Col>

        <Col span={6} xs={24} md={12}>
          { /* E-mail */}
          <Form.Item
            name="email"
            label={ t("signin:email") }
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail',
              },
              {
                required: true,
                message: 'Please input your E-mail',
              },
            ]}
            validateTrigger={'onBlur'}
          >
            <Input size="large"/>
          </Form.Item>
        </Col>

        <Col span={6} xs={24} md={12}>
          <Form.Item
            label={ t("signin:phone_number") }
          >
            <Space.Compact>
              <Form.Item
                id={'register_form_country_code'}
                className={'register_form_country_code'}
                name={'country_code'}
                max={'5'}
                style={{
                  width: '47%',
                }}
              >
                <Input
                  className={'country_code'}
                  style={{
                    borderRight: '0px',
                    backgroundColor: '#ffffff',
                    color: '#000'
                  }}
                  size={'large'}
                  disabled
                />
              </Form.Item>
              <Form.Item
                id={'register_form_phone_number'}
                className={'register_form_phone_number'}
                name="phone_number"
                rules={[
                  {
                    min: 6,
                    message: 'Phone number must be minimum 6 characters.'
                  },
                ]}
              >
                <Input size="large"/>
              </Form.Item>
            </Space.Compact>
          </Form.Item>


        </Col>

        <Col span={6} xs={24} md={12}>
          { /* Password */}
          <Form.Item
            name="password"
            label={ t("signin:password") }
            rules={[
              {
                min: 6,
                message: 'Password must be minimum 6 characters.'
              },
              {
                required: true,
                message: 'Please input your password',
              },
            ]}

          >
            <Input.Password size="large"/>
          </Form.Item>
        </Col>

        <Col span={6} xs={24} md={12}>
          { /* Password confirmation */}
          <Form.Item
            name="password_confirmation"
            label={ t("signin:passwordConfirmation") }
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password',
              },
              ({ getFieldValue }) => ({
                validator (_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match'))
                },
              }),
            ]}

          >
            <Input.Password size="large"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={6} xs={24} md={12}>

          { !hasCoupon ? (
            <Form.Item>
              <Link  to={"#"} onClick={() => setHasCoupon(true)}>{ t("signin:iHaveDiscountCode") }</Link>
            </Form.Item>
          ) : (
            <Form.Item
              name="coupon"
              label={ t("signin:coupon") }
              hasFeedback
            >
              <Input size="large" />
            </Form.Item>
          ) }

        </Col>
      </Row>


      <Form.Item
        name="checkbox"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(
                new Error('Should accept agreement')),
          },
        ]}
      >
        <Checkbox>
          <div dangerouslySetInnerHTML={{ __html: t("signin:terms") }} />
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          block
          loading={buttonLoading}
          style={{
            paddingBottom: '34px',
            paddingTop: '10px',
            fontWeight: 700,
            marginTop: '10px',
          }}
          htmlType="submit"
          className="btn-post-inscricao"
        >
          { t("signin:btnSignUp") }
        </Button>
      </Form.Item>
    </Form>
  )
}