import { Link } from 'react-router-dom'

export default function Logo({isHomePage, linkHome, width}) {
  if (width === undefined) {
    width = '5.2rem'
  }

  const logo = (color) => <svg
    id="OggoLogo"
    data-name="OggoLogo"
    style={{
      width: width,
    }}
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 221.18 93.13">

    <g>
      <path style={{
        fill: color,
        strokeWidth: '0px'
      }}
            d="m109.86,38.33c7.57,0,13.8,6.46,13.8,14.09s-6.22,14.27-13.8,14.27-13.86-6.52-13.86-14.27,6.22-14.09,13.86-14.09Zm0,22.78c4.52,0,8.04-3.7,8.04-8.69s-3.52-8.51-8.04-8.51-8.1,3.64-8.1,8.51,3.52,8.69,8.1,8.69Z"/>
      <path style={{
        fill: color,
        strokeWidth: '0px'
      }}
            d="m154.48,66.22c0,8.46-5.46,12.68-12.56,12.68-4.52,0-8.22-2.23-9.39-3.41-.47-.29-.53-1.12-.29-1.53l1.41-2.58c.35-.7,1.12-.94,1.82-.41.94.76,3.23,2.52,6.34,2.52,3.58,0,6.75-1.76,6.75-7.28v-1.47c-.47.29-3.41,1.94-6.93,1.94-7.4,0-13.09-6.28-13.09-14.21s5.34-14.15,12.8-14.15c5.05,0,8.1,2.76,8.57,3.23l.7-1.7c.18-.53.35-.94,1-.94h1.59c.7,0,1.29.65,1.29,1.29v26.01Zm-12.15-5.11c3.58,0,6.28-2.17,6.28-2.17v-11.21c-.53-1-2.88-3.82-6.52-3.82-4.64,0-7.69,3.99-7.69,8.57s3.52,8.63,7.93,8.63Z"/>
      <path style={{
        fill: color,
        strokeWidth: '0px'
      }}
            d="m187.01,66.22c0,8.46-5.46,12.68-12.56,12.68-4.52,0-8.22-2.23-9.39-3.41-.47-.29-.53-1.12-.29-1.53l1.41-2.58c.35-.7,1.12-.94,1.82-.41.94.76,3.23,2.52,6.34,2.52,3.58,0,6.75-1.76,6.75-7.28v-1.47c-.47.29-3.41,1.94-6.93,1.94-7.4,0-13.09-6.28-13.09-14.21s5.34-14.15,12.8-14.15c5.05,0,8.1,2.76,8.57,3.23l.7-1.7c.18-.53.35-.94,1-.94h1.59c.7,0,1.29.65,1.29,1.29v26.01Zm-12.15-5.11c3.58,0,6.28-2.17,6.28-2.17v-11.21c-.53-1-2.88-3.82-6.52-3.82-4.64,0-7.69,3.99-7.69,8.57s3.52,8.63,7.93,8.63Z"/>
      <path style={{
        fill: color,
        strokeWidth: '0px'
      }}
            d="m207.38,38.33c7.57,0,13.8,6.46,13.8,14.09s-6.22,14.27-13.8,14.27-13.86-6.52-13.86-14.27,6.22-14.09,13.86-14.09Zm0,22.78c4.52,0,8.04-3.7,8.04-8.69s-3.52-8.51-8.04-8.51-8.1,3.64-8.1,8.51,3.52,8.69,8.1,8.69Z"/>
    </g>
    <path style={{
      fill: color,
      strokeWidth: '0px'
    }}
          d="m39.25,51.02l-.21-42.1C17.58,9.05,0,28.01,0,51.02s17.72,42.1,39.25,42.1,39.25-19.01,39.25-42.1h-39.25Z"/>
    <path style={{
      fill: color,
      strokeWidth: '0px'
    }}
          d="m46,43.38h40.44C86.44,19.58,68.18,0,46,0c-.03,0-.05,0-.08,0l.08,43.38Z"/>
  </svg>

  const color = isHomePage ? '#ffffff' :   '#e60078';

  return (
    <>
      {linkHome === true ?
        <Link to="/">{ logo(color) }</Link>: logo(color)  }
    </>
  )
}