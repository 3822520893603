import { Space, Tabs } from 'antd'
import { NavLink } from 'react-router-dom'
import CategoriesRepository from './CategoriesRepository'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import SystemSectionHeaderComponent
  from '../Components/SystemSectionHeaderComponent'
import api from '../../Services/HttpService'
import CategoryListComponent from './Components/CategoryListComponent'
import useLanguage from '../../Hooks/useLanguage'
import qs from 'qs'

export default function CategoriesList () {
  const { t } = useTranslation(['common', 'categories'])
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const {getLanguage} = useLanguage()

  useEffect(() => {
    ReactGA.event({
      category: "system_categories",
      action: "system_categories_page_list",
      label: "Categories > List",
    });
  }, []);

  const fetchCategories = async (source) => {
    const response = await CategoriesRepository.list(qs.stringify({
      lang: getLanguage()
    }), source)
    setLoading(false)
    setData(response)
  }

  useEffect(() => {
    const CancelToken = api.CancelToken
    const source = CancelToken.source()

     fetchCategories(source)
  }, [])

  if (loading) {
    return <>
    Loading ...
    </>
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <SystemSectionHeaderComponent
          title={ t('categories:title') }
          button={
            <NavLink to={`/system/categories/create`}>
              <button type="button" className="btn bg-oggo-colour py-2 ">
                <span className="btn-inner--text">{ t('common:btnCreate') }</span>
              </button>
            </NavLink>
          }
        />
          <Tabs

            defaultActiveKey="expense1"
            items={[
              {
                key: 'income',
                label: t('common:incomes'),
                children: <CategoryListComponent t={t} key='income' type={'income'} data={data.income} />,
              },
              {
                key: 'expense',
                label: t('common:withdrawals'),
                children: <CategoryListComponent t={t} key='expense' type={'expense'} data={data.expense} />,
              },
            ]}
          />

      </Space>

    </>
  )
}