export default function useLanguage () {
  const languages = {
    // 'zh': 'zh',
    'en': 'en',
    'de': 'de-DE',
    'es': 'es-ES',
    'fr': 'fr-FR',
    'it': 'it-IT',
    'pt': 'pt-BR',
    'pt-PT': 'pt-PT'
  };

  const hasLanguage = () => {
    return localStorage.getItem('language') !== null
  }

  const getLanguage = () => {
    const storedLanguage = localStorage.getItem('language');

    if (storedLanguage !== null) {
      return storedLanguage;
    }

    const userLanguage = navigator.language || navigator.userLanguage || 'en';

    if (languages.hasOwnProperty(userLanguage)) {
      return languages[userLanguage];
    }

    const [languageCode, countryCode] = userLanguage.split("-");
    const languageCodeWithCountry = `${languageCode}-${countryCode.toUpperCase()}`;

    if (languages.hasOwnProperty(languageCodeWithCountry)) {
      return languages[languageCodeWithCountry];
    }

    if (languages.hasOwnProperty(languageCode)) {
      return languages[languageCode];
    }

    return 'en';
  };

  const saveLanguage = language => {
    localStorage.removeItem('language')
    localStorage.setItem('language', language)
  }

  return {
    hasLanguage,
    saveLanguage,
    getLanguage,
  }
}