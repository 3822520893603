import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <>
      <div
        className="container justify-content-center d-flex align-items-center vh-100">
        <main id="content" role="main">
          <div className="container text-center">

            <div className="mb-3">
              <img className="img-fluid" src="/svg/illustrations/oc-error.svg"
                   alt="Image Description" style={{width: "30rem;"}} />

            </div>
            <div className="mb-4">
              <p className="fs-4 mb-0">Oops! Looks like you followed a bad
                link.</p>
              <p className="fs-4">If you think this is a problem with us,
                please <a className="link" onClick={() => navigate(-1)}>tell us</a>.</p>
            </div>
            <a className="btn btn-primary"  onClick={() => navigate(-1)}>Go back</a>
          </div>
        </main>
      </div>
    </>
  )
}