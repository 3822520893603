import CategoriesRepository from '../System/Categories/CategoriesRepository'
import api from '../Services/HttpService'
import qs from 'qs'

export default function useCategories() {
  const getCategories = () => {
    // console.log(localStorage.getItem('categories'));
    return JSON.parse(
      localStorage.getItem('categories')
    )
  }

  const saveCategories = async (language) => {
    const CancelToken = api.CancelToken
    const source = CancelToken.source()

    const data = await CategoriesRepository.list(qs.stringify({
      lang: language
    }), source);

    localStorage.removeItem('categories')
    localStorage.setItem('categories', JSON.stringify(data))
  }

  const getCategoryById = (category_id) => {
    try {
      const categories = getCategories().income.concat(getCategories().expense)

      for (let x = 0; x < categories.length; x++) {
        if (categories[x].id === category_id) {
          try {
            return categories[x].i18n[0].name
          } catch (e) {
            return categories[x].name
          }
        }

        let subcategories = categories[x].subcategories

        for (let i = 0; i < subcategories.length; i++) {
          if (subcategories[i].id === category_id) {
            return subcategories[i].name;
            /*
            try {
              return subcategories[i].i18n[0].name;
            } catch (e) {
              return subcategories[x].name;
            }
            */
          }
        }
      }
    } catch (e) {
    }

    if (category_id === 'totals') {
      return 'Totals'
    }

    return 'Undefined category'
  }

  const getCategoryObject = (category_id) => {
    try {
      const categories = getCategories().income.concat(getCategories().expense)

      for (let x = 0; x < categories.length; x++) {
        if (categories[x].id === category_id) {
          return categories[x]
        }

        let subcategories = categories[x].subcategories

        for (let i = 0; i < subcategories.length; i++) {
          if (subcategories[i].id === category_id) {
            return subcategories[i];
          }
        }
      }
    } catch (e) {
    }

    if (category_id === 'totals') {
      return 'Totals'
    }

    return 'Undefined category'
  }

  return {
    saveCategories,
    getCategories,
    getCategoryById,
    getCategoryObject
  }
}
