import { useNavigate, useRouteError } from 'react-router-dom'

const ErrorBoundary = () => {
  const error = useRouteError();
  // console.error(error);
  // Uncaught ReferenceError: path is not defined
  const serializeErrorToJSON = (error) => {
    const serializedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
    return serializedError;
  }

  const navigate = useNavigate()

  return (
    <>
      <div
        className="container justify-content-center d-flex align-items-center vh-100">
        <main id="content" role="main">
          <div className="container text-center">

            <div className="mb-3">
              <img className="img-fluid" src="/svg/illustrations/oc-error.svg"
                   alt="Image Description" style={{width: "30rem;"}} />

              { /* serializeErrorToJSON(error) */ }
            </div>
            <div className="mb-4">
              <p className="fs-4 mb-0">Oops! Looks like something wrong happened</p>

            </div>
            <a className="btn btn-primary"  onClick={() => navigate(-1)}>Go back</a>
          </div>
        </main>
      </div>
    </>
  )

};

export default ErrorBoundary;