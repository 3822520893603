import { useEffect, useState } from 'react'
import Logo from '../../Components/Logo'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const defaultClasses = 'navbar navbar-expand-lg navbar-end navbar-absolute-top navbar-show-hide '

export default function SiteHeader ({ isHomePage }) {
  const navbarStyle = isHomePage ? ' navbar-dark ' : ' navbar-light '
  const { hash, key } = useLocation()
  const { t } = useTranslation(['translation', 'site'])
  const [scroll, setScroll] = useState(false)
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(
        window.scrollY > 50 ? 'navbar-scrolled' : 'navbar-untransitioned')
    })

    window.addEventListener('click', function (e) {
      try {
        if (!document.getElementById('jsMenu').contains(e.target)) {
          setIsMobileMenuOpened(false)
        }
      } catch (e) {
        //
      }
    })
  }, [])

  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1))
      targetElement?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [key, hash])

  return (
    <header
      id="header"
      className={defaultClasses + navbarStyle + scroll}
    >

      <div className="container">
        <nav id="jsMenu" className="js-menu navbar-nav-wrap">
          <NavLink className="navbar-brand" to="/">
            <Logo isHomePage={isHomePage}/>
          </NavLink>

          { /* Toggler */}
          <button
            onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation">
                        <span className="navbar-toggler-default">
                            <i className="bi-list"></i>
                        </span>

            <span className="navbar-toggler-toggled">
                            <i className="bi-x"></i>
                        </span>
          </button>
          { /* End Toggler */}

          { /* Collapse */}
          <div className={`collapse navbar-collapse ${isMobileMenuOpened
            ? 'show'
            : ''}`} id="navbarNavDropdown">
            <div className="navbar-absolute-top-scroller">
              <ul className="navbar-nav">

                { /* Home */}
                <li className="nav-item">
                  <Link
                    id="home"
                    className="nav-link"
                    to={{
                      pathname: '/',
                      hash: '#content',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_home')}
                  </Link>
                </li>
                { /* End Home */}


                { /* How it works */}
                <li className="nav-item">
                  <Link
                    id="howItWorksMenu"
                    className="nav-link"
                    to={{
                      pathname: '/',
                      hash: '#howItWorks',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_features')}
                  </Link>
                </li>
                { /* End how it works */}



                { /* Features */}
                {/*<li className="nav-item">*/}
                {/*  <Link*/}
                {/*    id="featuresMenu"*/}
                {/*    className="nav-link"*/}
                {/*    to={{*/}
                {/*      pathname: '/',*/}
                {/*      hash: '#features',*/}
                {/*    }}*/}
                {/*    role="button"*/}
                {/*    aria-expanded="false"*/}
                {/*    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}*/}
                {/*  >*/}
                {/*    {t('site:menu_features')}*/}
                {/*  </Link>*/}
                {/*</li>*/}
                {/*{ /* End Features *!/*/}


                { /* Features * /}
                <li className="nav-item">
                  <Link
                    id="testimonialsMenu"
                    className="nav-link"
                    to={{
                      pathname: '/',
                      hash: '#testimonials',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_testimonials')}
                  </Link>
                </li>
                { /* End Features */}

                { /* Pricing */}
                <li className="nav-item">
                  <Link
                    id="pricingMenu"
                    className="nav-link"
                    to={{
                      pathname: '/',
                      hash: '#pricing',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_pricing')}
                  </Link>
                </li>
                { /* End Pricing */}


                <li className="nav-item">
                  <Link
                    id="faqMenu"
                    className="nav-link"
                    to={{
                      pathname: '/',
                      hash: '#faq',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_faq')}
                  </Link>
                </li>
                { /* FAQ }
                <li className="nav-item">
                  <Link
                    id="faqMenu"
                    className="nav-link "
                    to={{
                      pathname: '/faq',
                      hash: '#faq',
                    }}
                    role="button"
                    aria-expanded="false"
                    onClick={() => setIsMobileMenuOpened(!isMobileMenuOpened)}
                  >
                    {t('site:menu_faq')}
                  </Link>
                </li>
                { /* End FAQ */}

                { /* Contact */}
                <li className="nav-item">
                  <NavLink to="/contact" className="nav-link">
                    {t('site:menu_contact')}
                  </NavLink>
                </li>
                { /* End Contact */}



                { /* Login */}
                <li className="nav-item">
                  <NavLink id="loginMenu"
                           className="nav-link"
                           to="/auth/login"
                           role="button"
                           aria-expanded="false"
                           onClick={() => setIsMobileMenuOpened(
                             !isMobileMenuOpened)}
                  >
                    <button className='btn btn-soft-dark'>
                      {t('site:menu_login')}
                    </button>
                  </NavLink>
                </li>


                <li className="nav-item">
                  <NavLink id="registerMenu"
                           className="nav-link"
                           to="/auth/register"
                           role="button"
                           aria-expanded="false"
                           onClick={() => setIsMobileMenuOpened(
                             !isMobileMenuOpened)}
                  >
                   <button className='btn start-free-trial'>
                     {t('site:menu_signup')}
                   </button>
                  </NavLink>
                </li>

                { /* End Login * / }
                                @endguest

                                { /* Button * / }
                                <li className="nav-item">
                                    @auth
                                    <a className="nav-link" hre f="{{ route('dashboard') }}"><span className="badge bg-primary rounded-pill ms-1">{{ __('site.menu_dashboard') }}</span></a>
                                    @else
                                    <a className="nav-link " href="{{ route('register') }}"><span className="badge bg-primary rounded-pill ms-1">{{ __('site.menu_signup') }}</span></a>
                                    @endauth
                                </li>

                                <li className="nav-item">
                                    @include('site.languages')
                                </li>
                                { /* End Button */}


              </ul>
            </div>
          </div>
          { /*{ /* End Collapse */}
        </nav>
      </div>
    </header>
  )
}