import { useState } from 'react'

// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
// https://jasonwatmore.com/post/2019/02/01/react-role-based-authorization-tutorial-with-example#private-route-jsx
// https://www.bezkoder.com/react-login-example-jwt-hooks/
export default function useAuth () {
  const getToken = () => {
    return localStorage.getItem('token')
  }

  const [token, setToken] = useState(getToken())

  const saveToken = userToken => {
    localStorage.setItem('token', userToken)
    setToken(token)
  }

  const logout = () => {
    localStorage.removeItem('language')
    localStorage.removeItem('deviceType')
    localStorage.removeItem('token')
    localStorage.removeItem('userInfo')
    localStorage.removeItem('currencies')
    localStorage.removeItem('categories')
  }

  return {
    setToken: saveToken,
    token,
    logout: logout,
  }
}