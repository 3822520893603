import countries from '../../Data/countries.json'

const CountriesRepository = {
  listAll: async () => {
    return countries.filter((country) => country.open_bank_service !== null);
  },

  getCountryCodeById: (id) => {
    return '+'+ countries.filter((i) => i.id === id)[0].country_code
  }
}


export default CountriesRepository;