import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'

export default function SitePricing({ t, language }) {
  const [isAnnually, setIsAnnually] = useState(false);
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate('/auth/register')
  }

  return (
    <>
      <div id="pricing"
           className="position-relative bg-img-start"

      >
        {/*
            style={{
             backgroundImage: `url('/svg/components/card-11.svg')`
           }}*/}

        <div className="container content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-3">
          <div className="w-md-75 w-lg-50 text-center mx-auto mb-9">
            <h1>
              {t('site:pricing_title')}
            </h1>
          </div>

          <div className="d-flex justify-content-center">
            <div className="form-check form-switch form-switch-between">
              <label className="form-check-label">
                {t('site:pricing_monthly')}
              </label>

              <input
                className="toggle-switch form-check-input"
                type="checkbox"
                onChange={() => setIsAnnually(!isAnnually)}
                checked={isAnnually} />

              <label className="form-check-label form-switch-promotion">
                {t('site:pricing_annually')} *
                <span className="form-switch-promotion-container">
                                <span className="form-switch-promotion-body">
                                    <svg className="form-switch-promotion-arrow" xmlns="http://www.w3.org/2000/svg"
                                         x="0px" y="0px" viewBox="0 0 99.3 57" width="48">
                                        <path fill="none" stroke="#bdc5d1" strokeWidth="4" strokeLinecap="round"
                                              strokeMiterlimit="10"
                                              d="M2,39.5l7.7,14.8c0.4,0.7,1.3,0.9,2,0.4L27.9,42"></path>
                                        <path fill="none" stroke="#bdc5d1" strokeWidth="4" strokeLinecap="round"
                                              strokeMiterlimit="10" d="M11,54.3c0,0,10.3-65.2,86.3-50"></path>
                                    </svg>

                                    <span className="form-switch-promotion-text">
                                        <span className="badge bg-primary rounded-pill ms-1">
                                            {t('site:pricing_discount')}
                                        </span>
                                    </span>
                                </span>
                            </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-n5 mt-lg-n10">

        <div className="position-relative zi-1 mb-10">
          <div className="row">


            {/*<div className="col-md mb-3 mb-md-0"  onClick={() => handleRegister()}>
              <div className="card h-100 zi-1">
                <div className="card-header text-center">
                  <div className="mb-2">
                    <span className="fs-5 align-top text-dark fw-semi-bold">€</span>
                    <span id="planFreeLabel" className="fs-1 text-dark fw-semi-bold">0</span>
                    <span>/{t('site:pricing_monthly_short')}</span>
                  </div>

                  <h3 className="card-title">
                    {t('site:pricing_starter')}
                  </h3>
                  <p className="card-text">
                    {t('site:pricing_starter_text')}
                  </p>
                </div>

                <div className="card-body d-flex justify-content-center py-0">

                  <ul className="list-checked list-checked-soft-bg-primary">
                    <li className="list-checked-item">
                      {t('site:pricing_feature_1user')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_3accounts')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_budget_planning')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_schedules')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_transactions')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_basic_reports')}
                    </li>
                  </ul>

                </div>

                <div className="card-footer text-center">
                  <div className="d-grid mb-2">
                    <Link to={"/auth/register"}>
                      <button
                        id="planFreeBtn"
                        type="button"
                        className="btn btn-ghost-primary btn-link"
                        data-id="1"
                      >
                        {t('site:pricing_starter_button')}
                      </button>

                      <p className="card-text text-opacity-50 small">No credit card required</p>

                    </Link>
                  </div>

                </div>
              </div>

            </div> */}

            <div className="col-md mb-3 mb-md-0" onClick={() => handleRegister()}>

              <div className="card bg-primary h-100 zi-1">
                <div className="card-header text-center">
                  <div className="text-white mb-2">
                    <span className="fs-5 align-top fw-semi-bold">{ t('site:pricing_symbol') }</span>
                    <span id="planIndividualLabel" className="fs-1 fw-semi-bold">
                        {isAnnually ? t('site:pricing_individual_yearly') : t('site:pricing_individual_monthly')}
                    </span>
                    <br />
                    <span>
                      {isAnnually ? t('site:pricing_yearly_short') : t('site:pricing_monthly_short') }
                    </span>
                  </div>

                  <h3 className="card-title text-white mt-4">
                    {t('site:pricing_individual')}
                  </h3>
                  <p className="card-text text-white-70">
                    {t('site:pricing_individual_text')}
                  </p>
                </div>

                <div className="card-body d-flex justify-content-center py-0">

                  <ul className="list-checked list-checked-soft-bg-light">
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_1user')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_5accounts')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_budget_planning')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_eu_bank_integrations')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_tags')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_schedules')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_transactions')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_documents')}
                    </li>
                    <li className="list-checked-item text-white">
                      {t('site:pricing_feature_all_reports')}
                    </li>
                  </ul>

                </div>

                <div className="card-footer text-center">
                  <div className="d-grid mb-2">
                    <Link to={"/auth/register"}>
                      <button
                      id="planIndividualBtn"
                      type="button"
                      className="btn btn-light btn-link"
                      data-id="2"
                    >
                      {t('site:pricing_individual_button')}
                    </button>
                    </Link>
                  </div>
                  <p className="card-text text-white-70 small">{ t('site:no_credit_card_required') }</p>
                </div>
              </div>

            </div>


            <div className="col-md mb-3 mb-md-0"  onClick={() => handleRegister()}>

              <div className="card h-100 zi-1">
                <div className="card-header text-center">
                  <div className="mb-2">
                    <span className="fs-5 align-top text-dark fw-semi-bold">{ t('site:pricing_symbol') }</span>
                      <span id="planFamilyLabel" className="fs-1 text-dark fw-semi-bold">
                        {isAnnually ? t('site:pricing_family_yearly') : t('site:pricing_family_monthly')}
                      </span>
                    <br />
                    <span>
                      {isAnnually ? t('site:pricing_yearly_short') : t('site:pricing_monthly_short') }
                    </span>
                  </div>

                  <h3 className="card-title mt-4">
                    {t('site:pricing_family')}
                  </h3>
                  <p className="card-text">
                    {t('site:pricing_family_text')}
                  </p>
                </div>

                <div className="card-body d-flex justify-content-center py-0">

                  <ul className="list-checked list-checked-soft-bg-primary">
                    <li className="list-checked-item">
                      {t('site:pricing_feature_5users')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_10accounts')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_budget_planning')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_eu_bank_integrations')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_tags')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_schedules')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_transactions')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_documents')}
                    </li>
                    <li className="list-checked-item">
                      {t('site:pricing_feature_all_reports')}
                    </li>
                  </ul>

                </div>

                <div className="card-footer text-center">
                  <div className="d-grid mb-2">
                    <Link to={"/auth/register"}>
                      <button
                      id="planFamilyBtn"
                      type="button"
                      className="btn btn-ghost-primary btn-link"
                      data-id="4">
                      {t('site:pricing_family_button')}
                    </button>
                    </Link>
                  </div>
                  <p className="card-text text-muted small">{ t('site:no_credit_card_required')}</p>
                </div>
              </div>

            </div>

          </div>
          {/*<figure className="position-absolute bottom-0 start-0 mb-n7 ms-n7 d-none d-sm-block"*/}
          {/*        style={{*/}
          {/*          width: '9rem'*/}
          {/*        }}>*/}
          {/*  <svg viewBox="0 0 522 504" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
          {/*    <path opacity="0.5"*/}
          {/*          d="M332 504H510.4C516.8 504 522 498.8 522 492.4V314C522 209.1 436.9 124 332 124C227.1 124 142 209.1 142 314C142 418.9 227.1 504 332 504Z"*/}
          {/*          fill="#00C9A7"/>*/}
          {/*    <path*/}
          {/*      d="M190 380H11.6C5.2 380 0 374.8 0 368.4V190C0 85.1 85.1 0 190 0C294.9 0 380 85.1 380 190C380 294.9 294.9 380 190 380Z"*/}
          {/*      fill="#377dff"/>*/}
          {/*  </svg>*/}
          {/*</figure>*/}

          {/*<figure*/}
          {/*  className="position-absolute top-0 end-0 mt-n8 me-n8 d-none d-sm-block"*/}
          {/*  style={{width: "10rem"}}*/}
          {/*>*/}
          {/*  <img*/}
          {/*    className="img-fluid"*/}
          {/*    src="/svg/components/dots-lg.svg"*/}
          {/*    alt="Personal finance manager dots"*/}
          {/*  />*/}
          {/*</figure>*/}

        </div>

      </div>


      { /*
      <div className="text-center mt-2 mb-8">
        <div className="card card-info-link card-sm">
          <div className="card-body">
            {t('site:faq_phrase_help')}
            <Link
              className="card-link ms-2"
              to="/faq">
              {t('site:faq_title')}
              <span className="bi-chevron-right small ms-1"></span>
            </Link>
          </div>
        </div>
      </div>
*/}

      { /*<div className="text-center">
        <div className=" card-sm">
          <div className="card-body">
            {t('site:pricing_annual_description')}
          </div>
        </div>
      </div>*/}
    </>
  );
}