import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd';
import { Link } from 'react-router-dom'

export default function SiteFaq () {
  const [t] = useTranslation(['site'])
  const basicItems = [
    {
      key: '1',
      label: <b>{ t('site:faq_question1') }</b>,
      children: <p>{t('site:faq_answer1')}</p>,
    },
    {
      key: '2',
      label: <b>{ t('site:faq_question2') }</b>,
      children: <p>{t('site:faq_answer2')}</p>,
    },
    {
      key: '3',
      label: <b>{ t('site:faq_question3') }</b>,
      children: <p>{t('site:faq_answer3')}</p>
    },
    {
      key: '4',
      label: <b>{ t('site:faq_question4') }</b>,
      children: <p>{t('site:faq_answer4')}</p>
    },
    {
      key: '5',
      label: <b>{ t('site:faq_question5') }</b>,
      children: <p>{t('site:faq_answer5')}</p>
    },
    {
      key: '6',
      label: <b>{ t('site:faq_question6') }</b>,
      children: <p>{t('site:faq_answer6')}</p>
    },
    {
      key: '7',
      label: <b>{ t('site:faq_question7') }</b>,
      children: <p>{t('site:faq_answer7')}</p>
    },
    {
      key: '8',
      label: <b>{ t('site:faq_question8') }</b>,
      children: <p dangerouslySetInnerHTML={{__html: t('site:faq_answer8')}}></p>
    },
    {
      key: '9',
      label: <b>{ t('site:faq_question9') }</b>,
      children: <p>{t('site:faq_answer9') }</p>
    },
    {
      key: '10',
      label: <b>{ t('site:faq_question10') }</b>,
      children: <p>{t('site:faq_answer10')}</p>
    },
    {
      key: '11',
      label: <b>{ t('site:faq_question11') }</b>,
      children: <p>{t('site:faq_answer11')}</p>
    },
  ];

  return (
    <>
      <main id="faq" role="main">

        <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-lg-1">

        <div className="w-md-75  text-center mx-md-auto">
            <h1>{t('site:faq_title')}</h1>
            <p>{ t('site:faq_description') }</p>
          </div>
        </div>



        <div className="container ">
          <div className="card-lg">
            {/*<div className="card-header bg-dark ">*/}
            {/*  <h1 className="card-title h2 text-white">*/}
            {/*    Basic*/}
            {/*  </h1>*/}
            {/*  /!*<p className="card-text text-white">aaa</p>*!/*/}
            {/*</div>*/}

            <div className="card-body">
              <Collapse
                ghost
                items={basicItems}
              />
            </div>
          </div>
        </div>

        <div className="text-center mt-2 mb-8">
          <div className="card card-info-link card-sm">
            <div className="card-body">
              {t('site:faq_phrase_help')}
              <Link
                className="card-link ms-2"
                to="/contact">
                {t('site:faq_contactus')}
                <span className="bi-chevron-right small ms-1"></span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}




