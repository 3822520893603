import { Button, Col, Grid, Row, Space } from 'antd'
import CategoriesIconComponent from './CategoriesIconComponent'
import { Link } from 'react-router-dom'
import React from 'react'
const { useBreakpoint } = Grid;

const isMobile = (screens) => {
  if (Object.entries(screens).length === 0) {
    return false
  }

  return !screens.md
}

const showData = (t, type, data, screens, isSubcategory) => {
   return data.map((record, index) => {
      return <React.Fragment key={index}>
        <Row justify="space-between" className={'category-list'}>
          <Col span={isMobile(screens) ? 18 : 16}>
            <Link to={record.customer_id !== null ?
              `/system/categories/${record.id}/edit` : '#'
            } >
              <Space size="small" style={{
                padding: '15px'
              }}>
                <CategoriesIconComponent
                  key={index}
                  icon={record.icon}
                  color={record.color}
                  bgColor={record.background_color}
                />

                {isSubcategory ? '-' : null}
                {record.name}
              </Space>
            </Link>
          </Col>

          <Col span={isMobile(screens) ? 5 : 5}>
            { /*{record.customer_id !== null ? (
              <Space size="middle" style={{
                padding: '18px'
              }}>
                <Link  to={`/system/categories/create/${record.id}`}>
                  <Button>{ isMobile(screens) ? '+' : t('categories:createSub') }</Button>
                </Link>
              </Space>
            ) : null} */}

            {record.parent_id === null ? (<Space size="middle" style={{
              padding: '18px'
            }}>
              <Link  to={`/system/categories/create/${record.id}`}>
                <Button>{ isMobile(screens) ? '+' : t('categories:createSub') }</Button>
              </Link>
            </Space>
            ) : null}
          </Col>
        </Row>

        { record.subcategories !== undefined && record.subcategories.length > 0 ? showData(t, type, record.subcategories, screens, true) : null }
      </React.Fragment>
    }
  )
}
export default function CategoryListComponent({t, type, data}) {
  const screens = useBreakpoint();

  return (
      <>
        <div
          className="card card-body table-responsive"
          id="section-header"
          style={{
          padding: '5px',
        }}>


            { showData(t, type, data, screens, false)}

        </div>
      </>
    )
}