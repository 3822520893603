import React, { useEffect, useState } from 'react'
import './SiteCarousel.css';

const SiteCarousel = ({t}) => {
  const [imagePaths, setImagePaths] = useState([]);
  const [imagePaths2, setImagePaths2] = useState([]);

  useEffect(() => {
    let originalPaths = [];
    for (let i = 1; i <= 30; i++) {
      let imageNumber = i.toString().padStart(2, '0');
      originalPaths.push(`bank_logos/l-${imageNumber}.png`);
    }

    let shuffledPaths = [...originalPaths];
    shuffledPaths.sort(() => Math.random() - 0.5);

    let shuffledPaths2 = [...originalPaths];
    shuffledPaths2.sort(() => Math.random() - 0.5);

    setImagePaths([...shuffledPaths, ...shuffledPaths]);
    setImagePaths2([...shuffledPaths2, ...shuffledPaths2]);
  }, []);

  return (
    <>
      <div className="container content-space-3 content-space-b-lg-3">

            <div className="w-md-6 w-lg-50 text-center mx-md-auto mb-6">
          <h2>
            { t('site:site_bank_connections_title') }
          </h2>
          <p className={'lead'}>
            { t('site:site_bank_connections_description') }
          </p>
        </div>




        <div>
          <div className="marquee-container">
            <div className="image-marquee">
              {imagePaths.map((path, index) => (
                <img key={index} src={path} alt={`Image ${index}`} className="carousel-image" />
              ))}
            </div>
          </div>
          <div className="marquee-container reverse">
            <div className="image-marquee">
              {imagePaths2.map((path, index) => (
                <img key={index} src={path} alt={`Image ${index}`} className="carousel-image" />
              ))}
            </div>
          </div>
        </div>


      </div>
  </>
  );
};

export default SiteCarousel;