import { notification } from 'antd'
import NOTIFICATION_DETAILS from './NotificationDetails'

/**
 * Two types of notifications,
 * - server, when the info comes from server
 * - fixed, using notificationDetails constants
 *
 * example server usage:
 * {
 *   type: 'server',
 *   subtype: 'error',
 *   message: `Invalid credentials`, //todo: translations
 *   description: data.message,
 * }
 * @param props
 */
const showNotification = (props) => {
  if (props.type === 'server') {
    return notification[props.subtype]({
      message: props.message,
      description: props.description,
    })
  } else {
    let t = null;

    if (undefined != props.translationFunc) {
      t = props.translationFunc
    } else {
      t = (item) => null
    }

    switch (props.subtype) {
      case 'notificationSentByEmail':
        return notification['success']({
          message: getTranslatedStringOrDefault(t('common:notification_sent_by_email_message'), NOTIFICATION_DETAILS.notificationSentByEmail.message),
          description: getTranslatedStringOrDefault(t('common:notification_sent_by_email_description'), NOTIFICATION_DETAILS.notificationSentByEmail.description),
        })
      break
      case 'success':
        return notification['success']({
          message:  getTranslatedStringOrDefault(t('common:notification_success_message'), NOTIFICATION_DETAILS.success.message),
          description:  getTranslatedStringOrDefault(t('common:notification_success_description'), NOTIFICATION_DETAILS.success.description),
        })
        break

      case 'created':
        return notification['success']({
          message: getTranslatedStringOrDefault(t('common:notification_success_message'), NOTIFICATION_DETAILS.success.message),
          description: getTranslatedStringOrDefault(t('common:notification_success_description'), NOTIFICATION_DETAILS.success.description),
        })
        break

      case 'updated':
        return notification['success']({
          message: getTranslatedStringOrDefault(t('common:notification_updated_message'),  NOTIFICATION_DETAILS.updated.message),
          description: getTranslatedStringOrDefault(t('common:notification_updated_description'),  NOTIFICATION_DETAILS.updated.description),
        })
        break

      case 'deleted':
        return notification['warning']({
          message: getTranslatedStringOrDefault(t('common:notification_deleted_message'),  NOTIFICATION_DETAILS.deleted.message),
          description: getTranslatedStringOrDefault(t('common:notification_deleted_description'),  NOTIFICATION_DETAILS.deleted.description),
        })
        break

      case 'error':
        return notification['error']({
          message: getTranslatedStringOrDefault(t('common:notification_error_message'), NOTIFICATION_DETAILS.error.message),
          description: getTranslatedStringOrDefault(t('common:notification_error_description'), NOTIFICATION_DETAILS.error.description),
        })
        break

      case 'contactEmailSent':
        return notification['success']({
          message: getTranslatedStringOrDefault(t('common:notification_contact_email_sent_message'), NOTIFICATION_DETAILS.contactEmailSent.message),
          description: getTranslatedStringOrDefault(t('common:notification_contact_email_sent_description'), NOTIFICATION_DETAILS.contactEmailSent.description),
        })
        break
    }
  }
}

const getTranslatedStringOrDefault = (message, defaultValue) => {
  return message ?? defaultValue;
}

export default showNotification