import React, { useEffect, useState } from 'react'
import CategoriesRepository from './CategoriesRepository'
import api from '../../Services/HttpService'
import SystemSectionHeaderComponent
  from '../Components/SystemSectionHeaderComponent'
import {
  ColorPicker,
  Button,
  Divider,
  Form,
  Input,
  Radio,
  Space,
  Row, Col,
} from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import CategoriesIconComponent from './Components/CategoriesIconComponent'
import showNotification from '../../Components/Notifications/Notification'
import useCategories from '../../Hooks/useCategories'
import useLanguage from '../../Hooks/useLanguage'

const CancelToken = api.CancelToken
const source = CancelToken.source()

export default function CategoriesCreate() {
  const [loading, setLoading] = useState(true)
  const [icons, setIcons] = useState()
  const { form } = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation(['common', 'categories'])
  const [buttonLoading, setButtonLoading] = useState(false)
  const [ parentData, setParentData] = useState({})
  const [formData, setFormData] = useState({
    color: '#fc0391',
    background_color: '#efefef'
  })
  const { getLanguage } = useLanguage()
  const { saveCategories } = useCategories()

  const { parentId } = useParams()

  const fetchData = async () => {
    const response = await CategoriesRepository.show(parentId, source)
    setParentData(response)
  }

  const fetchIcons = async () => {
    const response = await CategoriesRepository.icons(source)
    setLoading(false)
    setIcons(response)
  }

  const onFinish = async (values) => {
    setButtonLoading(true)
    try {
      values['color'] = values.color.toHexString()
    } catch (e) {
      // dont need to convert
    }

    try {
      values['background_color'] = values.background_color.toHexString()
    } catch (e) {
      // dont need to convert
    }

    if (parentId !== undefined) {
      values['parent_id'] = parentData.id
      values['type'] = parentData.type
    }

    const response = await CategoriesRepository.create(values)

    if (response) {
      showNotification({
        translationFunc: t,
        type: 'local',
        subtype: 'created',
      })
    }

    await saveCategories(getLanguage()).then(
      navigate('/system/categories')
    )
  }

  const handleChangeBgColor = (a, hex) => {
    setFormData({
      ...formData,
      background_color: hex
    });
  }

  const handleChangeColor = (a, hex) => {
    setFormData({
      ...formData,
      color: hex
    });
  }

  useEffect(() => {
    ReactGA.event({
      category: "system_categories",
      action: "system_categories_page_create",
      label: "Categories > Create",
    });

    fetchIcons()

    if (parentId !== undefined) {
      fetchData()
    }
  }, [])

  if (loading) {
    return <>
      Loading...
    </>
  }

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>

        <SystemSectionHeaderComponent
          title={ t('categories:title') }
          button={<Button
            onClick={() => navigate(-1)}
            type="button"
            className={"mt-1"}>
            { t('common:btnBack') }
          </Button>
          }
        />

        <div className="card card-body" id="form">
          <Form
            form={form}
            name="categories"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              color: formData.color,
              background_color: formData.background_color
            }}
            scrollToFirstError
            style={{ padding: 0 }}
          >

            {
              parentId === undefined ? (
                <Form.Item
                  name="type"
                  label={ t('categories:type') }
                  rules={[
                    {
                      required: true,
                      message: 'Please select the type',
                    },
                  ]}
                >
                  <Radio.Group size="large">
                    <Radio.Button value='income'>{t('common:income')}</Radio.Button>
                    <Radio.Button value='expense'>{t('common:withdrawal')}</Radio.Button>
                  </Radio.Group>
                </Form.Item>
              ) : null
            }

              <Form.Item
              name="name"
              label={ t('categories:name') }
              rules={[
                {
                  required: true,
                  message: 'Please input the tag name',
                },
                {
                  min: 3,
                  message: 'Name must be at least 4 characters.'
                }
              ]}
            >
              <Input size="large"
              />
            </Form.Item>


            <Row gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}>
              <Col span={6} xs={12} md={6}>

                <Form.Item
                  name="color"
                  label={ t('categories:color') }
                  rules={[
                    {
                      required: true,
                      message: 'Please input the color',
                    },
                  ]}
                >
                  <ColorPicker
                    size={'large'}
                    format={'hex'}
                    disabledAlpha={true}
                    placement={'bottom'}
                    onChange={handleChangeColor}
                  />
                </Form.Item>
              </Col>

              <Col span={6} xs={12} md={6}>
                <Form.Item
                  name="background_color"
                  label={ t('categories:background_color') }
                  rules={[
                    {
                      required: true,
                      message: 'Please input the background color',
                    },
                  ]}
                >
                  <ColorPicker
                    size={'large'}
                    placement={'bottom'}
                    format={'hex'}
                    disabledAlpha={true}
                    onChange={handleChangeBgColor} />
                </Form.Item>
              </Col>
            </Row>

               <Space wrap size={32}
               style={{
                 maxHeight: '350px',
                 overflowY: 'auto'
               }}>
                <Form.Item
                  name={'icon'}
                  label={ t('categories:icon') }
                  rules={[
                    {
                      required: true,
                      message: 'Please input the icon',
                    },
                  ]}
                >
                  <Radio.Group >

                {
                  icons.map((item, index) => {
                    // console.log(item.value)
                    return (
                      <div
                        key={index}
                        style={{
                        float: 'left',
                        marginRight: '15px',
                        marginBottom: '15px',
                        textAlign: 'center'
                      }}>
                        <CategoriesIconComponent
                          key={item.name}
                          icon={item.value}
                          color={formData.color}
                          bgColor={formData.background_color}
                        />
                        <br />
                        <Radio value={item.value}
                        style={{
                          marginTop: '4px',
                          marginLeft: '10px'
                        }}/>
                      </div>
                    )
                  })
                }

                  </Radio.Group>
                </Form.Item>

            </Space>

            <Divider />
            <Form.Item>
              <Button
                loading={buttonLoading}
                type="primary"
                size={"large"}
                htmlType="submit">
                { t('common:btnSave') }
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Space>

    </>
  );
}