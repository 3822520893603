import React, { useEffect, useState } from 'react'
import './SiteCookies.css'; // Import the CSS file

const CookiesMessage = ({ t }) => {
  const [showMessage, setShowMessage] = useState(true);

  const handleAccept = () => {
    setShowMessage(false);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesAccepted) {
      setShowMessage(false);
    }
  }, []);

  if (!showMessage) {
    return null;
  }

  return (
    <div className="cookies-message">
      <p>
        This website uses cookies to ensure you get the best experience. By using our site, you acknowledge that you have read and understand our{' '}
        <a href="/policies/privacy" target="_blank">Privacy Policy</a> and <a href="/policies/cookies" target="_blank">Cookies Privacy</a> .
      </p>
      <button onClick={handleAccept}>Accept</button>
    </div>
  );
};

export default CookiesMessage;
