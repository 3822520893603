import { Select, Form } from 'antd'
import { useEffect, useState } from 'react'
import CountriesRepository from '../Shared/Countries/CountriesRepository'

// Documentation in:
// https://ant.design/components/select
export function SelectWithAllCountries ({
  isRequired,
  formName,
  changeFunction,
  label
}) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  if (isRequired === undefined) {
    isRequired = true
  }

  if (formName === undefined) {
    formName = 'country_id'
  }

  if (label === undefined) {
    label = "Country"
  }

  if (changeFunction === undefined) {
    changeFunction = () => {}
  }

  const fetchCountries = async () => {
    /*await http.get('countries').then(response => {
        setData(response.data)
      }).catch(function (error) {
        const data = error.response.data
        showNotification({
          type: 'server',
          subtype: 'error',
          message: `Unexpected behaviour`, //todo: translations
          description: data.message,
        })
      })
    */
    setData(await CountriesRepository.listAll())
    setLoading(false)
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  if (loading) {
    return (
      <>
        Loading
      </>
    )
  }

  return (
    <>
      <Form.Item
        name={formName}
        label={ label }
        rules={[
          {
            required: isRequired,
            message: 'Please select your country',
          },
        ]}
      >
        <Select
          showSearch
          name={formName}
          className="select-component"
          size="large"
          placeholder="Select your country"
          filterOption={(inputValue, option) =>
            option.children.join('')
              .toLowerCase()
            .includes(inputValue.toLowerCase())
          }
          onChange={changeFunction}
        >
          {data.map(item => (
            <Select.Option
              key={item.id}
              value={item.id}
            >
              <img
                alt="country logo"
                loading={'lazy'}
                className="avatar avatar-xss avatar-circle me-2"
                src={`/vendor/countries-flags/country-${item.code.toLowerCase()}.svg`}
              />
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}
