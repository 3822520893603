export default function SiteTestimonials({ t }) {
  return (
    <div id="testimonials">
      <div className="container content-space-2 content-space-t-lg-3">
        <div className="w-md-65 w-lg-40 text-center mx-auto mb-9">
          <h2>{ t('site:site_testimonials_head') }</h2>
        </div>

        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="card shadow-none">
              <div className="card-body">
                <div className="mb-auto">
                  <p className="card-text">{ t('site:site_testimonials_example1') }</p>
                </div>
              </div>

              <div className="card-footer pt-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-circle"
                         loading={'lazy'}
                         src="/img/testimonials/01.jpg"
                         alt="James Anderson - Oggo customer" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="card-title mb-0">James Anderson</h5>
                    {/*<p className="card-text small">Business Manager</p>*/}
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="col-sm-6 col-lg-4">
            <div className="card bg-primary">
              <div className="card-body">
                <div className="mb-auto">
                  <p className="card-text text-white">
                    { t('site:site_testimonials_example2') }
                  </p>
                </div>
              </div>

              <div className="card-footer pt-0">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-circle"
                         loading={'lazy'}
                         src="/img/testimonials/02.jpg"
                         alt="Maria Garcia - oggo money" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="card-title text-white mb-0">Maria Garcia</h5>
                    {/*<p className="card-text small text-white-70">Oggo</p>*/}
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className="col-sm-6 col-lg-4">

            <div className="card shadow-none">
              <div className="card-body">

                  <div className="mb-auto">
                  <p className="card-text">
                    { t('site:site_testimonials_example3') }
                  </p>
                </div>
              </div>

              <div className="card-footer pt-0">

                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <img className="avatar avatar-circle"
                         loading={'lazy'}
                         src="/img/testimonials/03.jpg"
                         alt="Aisha Patel - oggo customer" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="card-title mb-0">Aisha Patel</h5>
                    {/*<p className="card-text small">Oggo user</p>*/}
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}