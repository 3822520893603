import { Link } from 'react-router-dom'

export default function SiteFooterBanner ({ t }) {
  return (
    <div className="bg-dark" style={{
      backgroundImage: `url('/svg/components/wave-pattern-light.svg')`,
    }}>
      <div className="container content-space-2">
        <div className="row justify-content-lg-between align-items-lg-center">
          <div className="col-md mb-5 mb-md-0">
            <h2 className="text-white mb-0">
              {t('site:footer_banner_text')}
            </h2>
          </div>

          <div className="col-md-auto">
            <div className="d-grid d-sm-flex gap-3">
              <Link className="btn btn-light btn-transition"
                    to="/auth/register">
                {t('site:get_started')}
              </Link>
              <Link
                className="btn btn-ghost-light btn-transition"
                to={{ pathname: '/', hash: '#pricing' }}
              >
                {t('site:menu_pricing')}
                <i className="bi-chevron-right small ms-1"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}