import { Navigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import useAuth from '../Hooks/useAuth'
import AuthLogout from '../System/Components/AuthLogout'

export default function ProtectedRoutes () {
  const { token } = useAuth()

  if (!token) {
    return (
      <Navigate to="/auth/login"/>
    )
  }

  return (
    <>
      <AuthLogout />
      <Outlet />
    </>
  )
}