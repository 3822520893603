import SiteLanguageSelector from './SiteLanguageSelector'
import { Link } from 'react-router-dom'
import Logo from '../../Components/Logo'

export default function SiteFooter ({ t }) {
  return (
    <>
      <footer className="bg-dark">
        <div className="container pb-1 pb-lg-5">
          <div className="row content-space-t-2">
            <div className="col-lg-3 mb-7 mb-lg-0">

              <div className="mb-5">
                <Logo isHomePage={true} linkHome={true} />
              </div>

              <ul className="list-unstyled list-py-1">
                <li className="small text-white mt-3">
                  Tailor-made system, designed to empower your savings journey and illuminate your spending patterns, we’re here to help you achieve financial clarity and control.
                </li>
                <li className="small text-white mt-3">
                  We <i className="far fa-heart" style={{ color: '#FF7373' }}></i> personal finance management.
                </li>
              </ul>

            </div>


            <div className="col-sm offset-md-1 mb-7 mb-sm-0">
              <h5 className="text-white mb-3">Company</h5>

              <ul className="list-unstyled list-py-1 mb-0">
                <li>
                  <Link className="link-sm link-light" to='/about'>
                    {t('site:menu_about')}
                  </Link>
                </li>


                <li>
                  <Link
                    className="link-sm link-light"
                    to='/#howItWorks'
                  >
                    {t('site:menu_features')}
                  </Link>
                </li>


                <li>
                  <Link
                    className="link-sm link-light"
                    to={{ pathname: '/', hash: '#pricing' }}
                  >
                    {t('site:menu_pricing')}
                  </Link>
                </li>


                {/*<li>*/}
                {/*  <a className="link-sm link-light" href='https://blog.oggo.money/' target="_blank">*/}
                {/*    {t('site:menu_blog')}*/}
                {/*  </a>*/}
                {/*</li>*/}


              </ul>

            </div>


            {/*<div className="col-sm mb-7 mb-sm-0">
              <h5 className="text-white mb-3">Learn</h5>

              <ul className='list-unstyled list-py-1 item-mt-1'>
                <li><a className="link-sm link-light" href="#">
                  How to save money?
                </a></li>

                <li><a className="link-sm link-light" href="#">
                  How to track expenses?
                </a></li>
                <li><a className="link-sm link-light" href="#">
                  How to Budget?
                </a></li>

                <li><a className="link-sm link-light" href="#">
                  How to Deal with Financial Difficulties?
                </a></li>

                <li><a className="link-sm link-light" href="#">
                  How to Manage Your Money?
                </a></li>

                <li>
                  <a className="link-sm link-light" href="#">
                    How Does Personal Finance Affect Everyday Life?
                  </a>
                </li>
              </ul>

            </div>*/}

            <div className="col-sm mb-7 mb-sm-0">
              <h5 className="text-white mb-3">{t('site:site_footer_personal_finance_title')}</h5>
              <ul className='list-unstyled list-py-1 item-mt-1'>
                <li>
                  <Link className="link-sm link-light" to="/what-is-personal-finance">
                    {t('site:site_footer_personal_finance_what')}
                  </Link>
                </li>

                <li>
                  <Link className="link-sm link-light" to='/why-personal-finance-is-important'>
                    {t('site:site_footer_personal_finance_why')}
                  </Link>
                </li>

                <li>
                  <Link className="link-sm link-light" to='/when-should-you-start-planning-your-personal-finances'>
                    {t('site:site_footer_personal_finance_when')}
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-sm">
            </div>



          </div>





          <div className="border-top border-white-10 my-7"></div>

          <div className="row mb-7">
            <div className="col-sm mb-3 mb-sm-0">

              <ul
                className="list-inline list-separator list-separator-light mb-0">


                <li className="list-inline-item">
                  <span className="link-sm link-light">&copy; Oggo 2024. All rights reserved.</span>
                </li>

                <li className="list-inline-item">
                  <Link className="link-sm link-light" to="/policies/terms">
                    {t('site:menu_terms_condition')}
                    <i className="bi-box-arrow-up-right small ms-1"></i>
                  </Link>
                </li>


                <li className="list-inline-item">
                  <Link className="link-sm link-light" to="/policies/privacy">
                    {t('site:menu_privacy_policy')}
                    <i className="bi-box-arrow-up-right small ms-1"></i>
                  </Link>
                </li>


                <li className="list-inline-item">
                  <Link className="link-sm link-light" to="/policies/cookies">
                    {t('site:menu_policy_cookies')}
                    <i className="bi-box-arrow-up-right small ms-1"></i>
                  </Link>
                </li>
              </ul>

            </div>

            <div className="col-sm-auto">

              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <a
                    aria-label="Link for youtube"
                    name="Link for youtube channel"
                    className="btn btn-soft-light btn-xs btn-icon"
                    href="https://www.youtube.com/@OggoMoney"
                    target="_blank">
                    <i className="bi-youtube"></i>
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    aria-label="Link for instagram"
                    name="Link for instagram"
                    className="btn btn-soft-light btn-xs btn-icon"
                    href="https://www.instagram.com/oggomoney/"
                    target="_blank">
                    <i className="bi-instagram"></i>
                  </a>
                </li>

                <li className="list-inline-item">
                  <a
                    aria-label="Link for spotify"
                    name="Link for spotify"
                    className="btn btn-soft-light btn-xs btn-icon"
                    href="https://open.spotify.com/show/0HVumMdM6X9OyzskO1mwb5"
                    target="_blank">
                    <i className="bi-spotify"></i>
                  </a>
                </li>


                <li className="list-inline-item">

                  <SiteLanguageSelector />
                </li>
              </ul>

            </div>
          </div>


          <div className="w-md-85 text-lg-center mx-lg-auto">

            <p className="text-white small">
              Oggo.money is a sophisticated personal finance manager, leveraging GoCardless technology for secure access to bank accounts. Additionally, Oggo.money is a registered trademark in Portugal under the process number 712059. Oggo.money is committed to delivering secure and efficient personal financial management, ensuring compliance with the highest regulatory standards.
            </p>
          </div>

        </div>
      </footer>
    </>
  )
}
