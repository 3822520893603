import { Col, Row } from 'antd'

export default function SystemSectionHeaderComponent({title, button}) {
  return (
    <>
      <div className="card card-body" id="section-header">

        <Row wrap={false}>
          <Col flex="auto">
            <h2 className="mt-1 font-weight-bolder">
              { title }
            </h2>
          </Col>


          <Col flex="none">
            { button }
          </Col>
        </Row>

      </div>
    </>
  )
}