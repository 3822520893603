const NOTIFICATION_DETAILS = {
  success: {
    message: 'Item created',
    description:
      'Your item has been created successfully',
  },
  updated: {
    message: 'Item updated',
    description:
      'Your item has been updated successfully',
  },
  deleted: {
    message: 'Item deleted',
    description:
      'Your item has been deleted successfully',
  },
  error: {
    message: 'Something went wrong',
    description: 'Please check the error message and try again',
  },
  notificationSentByEmail: {
    message: 'Notification sent',
    description:
      'You will receive a notification by email',
  },
  contactEmailSent: {
    message: 'Message sent',
    description: 'Thank you for contacting us',
  }
}

export default NOTIFICATION_DETAILS