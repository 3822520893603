import SiteHeader from './Components/SiteHeader'
import SiteFooterBanner from './Components/SiteFooterBanner'
import SiteFooter from './Components/SiteFooter'
import { useTranslation } from 'react-i18next'

export default function SiteAbout () {
  const [t] = useTranslation(['site'])

  return (
    <>

      <SiteHeader isHomePage={false}/>

      <div id='top'> </div>
        <main id="about" role="main">

        <div className="container content-space-1 content-space-md-2">
          <div className="row justify-content-sm-center">
            <div className="col-sm-10 col-md-8 col-lg-7">

              <div className="text-center mb-7">
                <h1 className="display-4"
                    dangerouslySetInnerHTML={{
                      __html: t('site:site_about_header')
                    }}/>
                <p className="lead">
                  { t('site:site_about_header_description') }
                </p>
              </div>

            </div>
          </div>


          <div className="position-relative mb-5">

              <img
                className="img-fluid"
                src="/img/about.jpg"
                loading={'lazy'}
                alt="About personal finance manager" />
          </div>
        </div>




        <div className="container content-space-b-1 content-space-b-md-3">
          <div className="row justify-content-sm-center">
            <div className="col-sm-10 col-md-8 col-lg-7">

              <div className="mb-7">
                <h3>
                  { t('site:site_about_vision') }
                </h3>
                <p>
                  { t('site:site_about_vision_description') }
                </p>
              </div>


              <div className="d-grid gap-5">

                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M5 15L3 21.5L9.5 19.5L5 15Z" fill="#035A4B"></path>
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.5 21C18.7467 21 23 16.7467 23 11.5C23 6.2533 18.7467 2 13.5 2C8.2533 2 4 6.2533 4 11.5C4 16.7467 8.2533 21 13.5 21ZM13.5 14.0061L11.5463 15.0332C11.3124 15.1562 11.0232 15.0663 10.9003 14.8324C10.8513 14.7393 10.8344 14.6326 10.8522 14.5289L11.2254 12.3533L9.6447 10.8126C9.4555 10.6282 9.45165 10.3254 9.63605 10.1362C9.7095 10.0609 9.8057 10.0118 9.9098 9.9967L12.0942 9.67925L13.0711 7.69985C13.1881 7.46295 13.4749 7.3657 13.7118 7.4826C13.8061 7.52915 13.8825 7.60555 13.9291 7.69985L14.9059 9.67925L17.0903 9.9967C17.3517 10.0347 17.5329 10.2774 17.4949 10.5388C17.4798 10.6429 17.4307 10.7392 17.3554 10.8126L15.7748 12.3533L16.1479 14.5289C16.1926 14.7893 16.0177 15.0366 15.7573 15.0813C15.6537 15.0991 15.5469 15.0822 15.4538 15.0332L13.5 14.0061Z" fill="#035A4B"></path>
                      </svg>
                    </span>

                  </div>

                  <div className="flex-grow-1 ms-4">
                      <h6>
                        { t('site:site_about_mission_title1') }
                      </h6>
                      <p>
                        { t('site:site_about_mission_title1_description') }
                      </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <span className="svg-icon svg-icon-sm text-primary">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.3" d="M10.9607 12.9128H18.8607C19.4607 12.9128 19.9607 13.4128 19.8607 14.0128C19.2607 19.0128 14.4607 22.7128 9.26068 21.7128C5.66068 21.0128 2.86071 18.2128 2.16071 14.6128C1.16071 9.31284 4.96069 4.61281 9.86069 4.01281C10.4607 3.91281 10.9607 4.41281 10.9607 5.01281V12.9128V12.9128Z" fill="#035A4B"></path>
                        <path d="M12.9607 10.9128V3.01281C12.9607 2.41281 13.4607 1.91281 14.0607 2.01281C16.0607 2.21281 17.8607 3.11284 19.2607 4.61284C20.6607 6.01284 21.5607 7.91285 21.8607 9.81285C21.9607 10.4129 21.4607 10.9128 20.8607 10.9128H12.9607V10.9128Z" fill="#035A4B"></path>
                        </svg>
                    </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h6>
                      { t('site:site_about_mission_title2') }
                    </h6>
                    <p>
                      { t('site:site_about_mission_title2_description') }
                    </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="flex-shrink-0">
                  <span className="svg-icon svg-icon-sm text-primary">

                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="com/com014">
                        <path id="vector" opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M4 4L11.6314 2.56911C11.875 2.52343 12.125 2.52343 12.3686 2.56911L20 4V11.9033C20 15.696 18.0462 19.2211 14.83 21.2313L12.53 22.6687C12.2057 22.8714 11.7943 22.8714 11.47 22.6687L9.17001 21.2313C5.95382 19.2211 4 15.696 4 11.9033L4 4Z" fill="#035A4B"></path>
                        <path id="group" fillRule="evenodd" clipRule="evenodd" d="M9.5 10.5C9.5 9.11929 10.6193 8 12 8C13.3807 8 14.5 9.11929 14.5 10.5V11C15.0523 11 15.5 11.4477 15.5 12V15C15.5 15.5523 15.0523 16 14.5 16H9.5C8.94772 16 8.5 15.5523 8.5 15V12C8.5 11.4477 8.94772 11 9.5 11V10.5ZM12 9C11.1716 9 10.5 9.67157 10.5 10.5V11H13.5V10.5C13.5 9.67157 12.8284 9 12 9Z" fill="#035A4B"></path>
                        </g>
                        </svg>
                  </span>
                  </div>

                  <div className="flex-grow-1 ms-4">
                    <h6>
                      { t('site:site_about_mission_title3') }
                    </h6>
                    <p>
                      { t('site:site_about_mission_title3_description') }
                    </p>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>


        <SiteFooterBanner t={t}/>
      </main>
      <SiteFooter t={t}/>
    </>
  )
}
