import React from 'react';
import Avatar from 'antd/es/avatar/avatar';

export default function CategoriesIconComponent({ icon, isSelected, bgColor, color, size }) {
  if (size === undefined) {
    size = 'large'
  }

  return (
    <Avatar
      size={size}
      icon={
        <i className={`demo-icon ${icon}`}
        style={{
          color: color ? color : ''
        }}>
          {String.fromCharCode(icon)}
        </i>
      }
      style={{
        backgroundColor: bgColor, // Utilize passed bgColor
        // Remove styles related to selection & alignment from here if they are static
      }}
      className={isSelected ? 'selected-avatar' : ''} // Apply class conditionally
    />
  );
}
