import showNotification from '../../Components/Notifications/Notification'

const unhandledErrors = [
  'CanceledError',
  'TypeError',
]

// todo: add future specific handlers for each errors
// const handlers = []

const ExceptionHandler = {
  catch: (err) => {
    if (err instanceof Error) {
      // console.log(err.name, err.message)
      if (!unhandledErrors.includes(err.name)) {
        if (err.name === 'AxiosError') {
          showNotification({
            type: 'server',
            subtype: 'error',
            message: `Hey, something wrong happened`, //todo: translations
            description: err.response.data.message,
          })
        } else {
          showNotification({
            type: 'server',
            subtype: 'warning',
            message: `Hey, something wrong happened`, //todo: translations
            description: err.message,
          })
        }
      }
    }
  },
}

export default ExceptionHandler