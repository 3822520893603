import { useState } from 'react'

export default function useDeviceType() {
  const getDeviceType = () => {
    return localStorage.getItem('device')
  }

  const [deviceType, setDeviceType] = useState(getDeviceType())

  const saveDeviceType = device => {
    if (localStorage.getItem('device') === null) {
      localStorage.setItem('device', device)
      setDeviceType(device)
    }
  }

  return {
    setDeviceType: saveDeviceType,
    getDeviceType: getDeviceType,
    deviceType
  }
}