import { LoadingOutlined } from '@ant-design/icons'

export default function LoadingComponent () {
  return (
    <>
      <div id="loading">
        <LoadingOutlined style={{ fontSize: '70px', color: '#08c' }}/>
      </div>
    </>
  )
}