import getCurrencyById from '../Currencies/CurrencyRepository'

export function getUrlParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export function isInt (n) {
  return Number(n) === n && n % 1 === 0
}

export function isFloat (n) {
  return Number(n) === n && n % 1 !== 0
}

export function getAccountType(translationFunction, string) {
  return translationFunction('accounts:' + string);
}

export function getScheduleStatus(translationFunction, status) {
  return translationFunction('common:' + status) ?? null
}

export function getSchedulePeriod(translationFunction, period) {
  return translationFunction('schedules:' + period.toUpperCase()) ?? null
}

export function getScheduleFrequency(translationFunction, frequency) {
  return translationFunction('schedules:' + frequency.toUpperCase()) ?? null
}

export function getStatus(translationFunction, status, withBadge) {
  if (withBadge === undefined || withBadge === true) {
    return <span className={`badge badge-sm ${status  === 'processed' ? 'bg-success' : 'bg-danger' }`}>
    { translationFunction('common:' + status) }
  </span>
  }

  return translationFunction('common:' + status)
}

export function showAmount (currencyId, locale, balance) {
  const currency = getCurrencyById(currencyId)
  let code = currencyId;

  if (undefined !== currency) {
    code = currency.code;
  }

  return new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency: code,
    },
  ).format(balance / 100)
}

/**
 * The backend is waiting for a integer number
 * ex: 12356710
 *
 * it will be converted to user later as
 * ex: 123567.10
 *
 * @param value
 * @returns {string|*}
 */
export function fixAmount(value) {
  if (isInt(value)) {
    return `${value}00`;
  }

  if (isFloat(value)) {
    const [integerPart, decimalPart] = value.toString().split('.');
    let decimals = decimalPart || '';

    if (decimals.length < 2) {
      decimals += '0';
    }

    return `${integerPart}${decimals}`;
  }

  throw new Error('Unexpected format');
}