import axios from 'axios'
import logger from './LoggerService'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
})

api.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem('token')

    try {
      const userData = JSON.parse(localStorage.getItem('userInfo'));

      if (userData?.customer?.id !== null) {
        config.headers['x-customer'] = userData.customer.id
      }

      if (userData?.user?.email !== null && userData?.user?.language !== 'zh') {
        config.headers['x-user-email'] = userData.user.email
      }
    } catch (e) {

    }

    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

api.interceptors.response.use(null, error => {
  const expectedError =
    error.response && error.response.status == 500
  /*
    error.response.status >= 400 &&
    error.response.status < 500*/

  if (!expectedError) {
    //logger.log(error)
    // toast.error('An unexpected error ocurred.')
  }

  return Promise.reject(error)
})

api.CancelToken = axios.CancelToken
export default api