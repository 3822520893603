import { Outlet } from "react-router-dom";
import "./css/theme.min.css";
import ScrollToTop from './Components/ScrollToTop'

export default function SiteLayout() {
  return (
    <>
      <ScrollToTop />
      <Outlet/>
    </>
  );
}