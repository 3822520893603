import { REACT_APP_DEVICE_NAME } from '../config'
import { Button, Divider, Form, Input, notification } from 'antd'
import Logo from '../Components/Logo'
import { Link, useNavigate } from 'react-router-dom'
import http from '../Services/HttpService'
import useAuth from '../Hooks/useAuth'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import showNotification from '../Components/Notifications/Notification'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { getUrlParam } from '../System/Services/helperFunctions'
import useDeviceType from '../Hooks/useDeviceType'
import LoadingComponent from '../System/Components/LoadingComponent'
import SEO from '../Site/Components/SiteSEO'

export default function AuthLogin () {
  const { contextHolder } = notification.useNotification()
  const [loading, setLoading] = useState(true)

  const [buttonLoading, setButtonLoading] = useState(false)
  const { setToken } = useAuth()
  const navigate = useNavigate()
  const {t} = useTranslation(["login"]);
  const { deviceType, setDeviceType } = useDeviceType()

  useEffect(() => {
    const myPath = window.location.pathname + window.location.search;
    setDeviceType(getUrlParam('app') ?? REACT_APP_DEVICE_NAME)

    ReactGA.send({
      hitType: "pageview",
      page: myPath,
      title: "Login"
    });

    setLoading(false)
  }, []);

  const onFinish = (values, api) => {
    values['device_name'] = deviceType

    setDeviceType(deviceType)
    setButtonLoading(true)

    http.post('/auth/login', values)
      .then(response => {
        const data = response.data
        setToken(data.token)
        navigate('/system/preparing')
      }).catch(function (error) {
      const data = error.response.data
      showNotification({
        type: 'server',
        subtype: 'error',
        message: `Invalid credentials`, //todo: translations
        description: data.message,
      })
      setButtonLoading(false)
    })
  }

  if (loading) {
    return <LoadingComponent />
  }


  return (
    <>
      {contextHolder}

      <SEO
        title={'oggo.money - log in'}
        name={'oggo.money - login'}
        description={'Welcome back! Log in to continue simplifying your finances with our efficient budget tracker. Effortless expense management awaits you!'}
        type={'product'}
      />

      <div className="container content-space-2 content-space-lg-3">
        <div className="flex-grow-1 mx-auto"
             style={{ maxWidth: '28rem' }}>

          <div className="text-center mb-5 mb-md-7">

            <Logo width={'9rem'} isHomePage={false} linkHome={deviceType === 'webapp'}/>

            <br/><br/>
            <h2 className="h2 p2">{ t('login:headerText') }</h2>
            <p>{ t('login:headerPhrase') }</p>
          </div>

          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: 'Please input your email',
                },
              ]}
              validateTrigger={'onBlur'}
              className="mb-4"
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon"/>}
                placeholder="E-mail"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password',
                },
              ]}
            >
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon"/>}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            {/*<Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{ t('login:rememberMe') }</Checkbox>
              </Form.Item>
            </Form.Item>*/}

            <Form.Item>
              <Button
                type="primary"
                block
                loading={buttonLoading}
                htmlType="submit"
                style={{
                  paddingBottom: '34px',
                  paddingTop: '10px',
                  fontWeight: 700,
                }}
              >
                { t('login:btnSignIn') }
              </Button>
            </Form.Item>

            <Form.Item>
              <Link className="login-form-forgot" to="/auth/forgot-password">
                { t('login:forgotPassword') }
              </Link>
            </Form.Item>


            <Divider/>

            <Form.Item>
              <p>
                { t('login:createAccount') }</p>

                <Link to="/auth/register"
                      className="text-primary text-gradient font-weight-bold">
                  <Button
                    block
                    style={{
                      paddingBottom: '34px',
                      paddingTop: '10px',
                      fontWeight: 700,
                    }}
                  >
                    { t('login:btnCreateAccount') }
                  </Button>

                  </Link>

            </Form.Item>

          </Form>
        </div>
      </div>
    </>
  )
}