import React from 'react';
import { Link, NavLink } from 'react-router-dom'

export default function SiteBannerC({ t }) {
  return (
    <>
      <div className="gradient-x-overlay-sm-primary position-relative overflow-hidden">
        <div className="container content-space-t-1 content-space-b-1">
          <div
            className="row justify-content-center align-items-md-center mb-7">
            <div className="col-md-6 mb-7 mb-md-0">

              <div className="pe-lg-3 mb-7">
                <p className={'lead text-50 md-mb-2'}>
                  { t('site:site_head_top_cta') }
                </p>
                <h1 className="text-black mb-3 mb-md-5">
                  { t('site:site_head_cta') }
                </h1>
                <p className="lead text-black-70">{ t('site:site_text_cta') }</p>
              </div>


              <div className="d-grid d-sm-flex gap-3">
                <Link  to="/auth/register"
                className='btn btn-soft-dark start-free-trial btn-transition'>
                   {t('site:get_x_days_free')} <i className="bi-chevron-right small ms-1"></i>

                </Link>

                <Link
                  className="btn btn-soft-dark btn-transition"
                  to={{ path: '/', hash: '#pricing' }}>
                  {t('site:menu_pricing')}
                  <i className="bi-chevron-right small ms-1"></i>
                </Link>

              </div>


              <div className=" d-grid d-sm-flex align-items-sm-center gap-3 mt-4">


                <div className="offer-description">
                  <svg style={{
                    width: '1.2rem',
                    color: '#2f6ad9',
                    marginRight: '0.2rem'
                  }}
                       stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>

                  { t('site:no_credit_card_required') }

                </div>


                <div className="offer-description">
                  <svg style={{
                    width: '1.2rem',
                    color: '#2f6ad9',
                    marginRight: '0.2rem'
                  }}
                       stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round"
                          strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>

                  { t('site:cancel_anytime') }

                </div>


              </div>

            </div>


            <div className="col-sm-10 col-md-6">
              <img loading='lazy' className="img-fluid" src="/img/banner2.webp"
                   alt="Easy way to track your expenses" />
            </div>


            <div className="scroll"></div>

          </div>

        </div>


      </div>


    </>
  );
}