import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { useIdleTimer } from 'react-idle-timer'
import useAuth from '../../Hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const timeout = 300_000 // 5 minutes
const promptBeforeIdle = 60_000 // 1 minute

/*
const timeout = 300_000 // 5 minutes
const promptBeforeIdle = 60_000 // 1 minute

const timeout = 10_000
const promptBeforeIdle = 6_000
*/

// https://codesandbox.io/s/confirm-prompt-y8ew9s?from-embed=&file=/src/styles.css:175-389
export default function AuthLogout() {
  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const { logout } = useAuth()
  const { t } = useTranslation(['common'])
  const navigate = useNavigate()

  const onIdle = () => {
    setState('Idle')
    //setOpen(false)
    logout()
    navigate('/auth/login')
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })


  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }


  if (state === 'Prompted') {
    return (<>
        { t('common:autoLogoutTitle') }
        <Modal
          title={ t('common:autoLogoutTitle') }
          open={open}
          centered
          onOk={handleStillHere}
          okText={ t('common:autoLogoutButton') }
          cancelButtonProps={{ style: { display: 'none' } }}
          closeIcon={<></>}
        >
          <p>{ t('common:autoLogoutText')
            .replace('%remaining%', remaining)
          }</p>
        </Modal>
     </>
    );
  }
};
