import { Col, Row } from 'antd'

export default function SystemSectionRemoveItemComponent({title, button}) {
  return (
    <>
      <div className="card card-body" id="remove-item">

        <Row wrap={false}>
          <Col flex="auto">
            <h5 className="mt-1 font-weight-bolder">
              { title }
            </h5>
          </Col>

          <Col flex="none">
            { button }
          </Col>
        </Row>

      </div>
    </>
  )
}