import React, { useState } from 'react'

export default function SiteFeaturesAccordion({ t }) {
  const [line, setLine] = useState(1)

  return (
    <>
         <div className="overflow-hidden">
          <div  id='howItWorks' className="container content-space-t-3 content-space-t-lg-5 content-space-b-2 content-space-b-lg-3">

            <div className="row align-items-md-center">

              <div className="col-md-6 order-md-2 mb-7 mb-md-0">

                <div className="tab-content">
                  <div className={`tab-pane ${line === 1 ? 'active' : 'fade' }`}>
                    <img className="img-fluid shadow-xl rounded-2"
                         loading={'lazy'}
                         src="/img/features/01-bank-connection.webp"
                         alt="Linking your bank account" />
                  </div>

                  <div className={`tab-pane ${line === 2 ? 'active' : 'fade' }`}>
                    <img className="img-fluid shadow-xl rounded-2"
                         loading={'lazy'}
                         src="/img/features/02-transactions.webp"
                         alt="Transactions, tracking expenses" />
                  </div>

                  <div className={`tab-pane ${line === 3 ? 'active' : 'fade' }`}>
                    <img className="img-fluid shadow-xl rounded-2"
                         loading={'lazy'}
                         src="/img/features/03-reports.webp"
                         alt="How to create a budget" />
                  </div>

                  <div className={`tab-pane ${line === 4 ? 'active' : 'fade' }`}>
                    <img className="img-fluid shadow-xl rounded-2"
                         loading={'lazy'}
                         src="/img/features/04-budgeting.webp"
                         alt="Creating a budget for personal finances" />
                  </div>
                </div>

              </div>

              <div className="col-md-6 mt-5 mt-md-0">
                <div className="pe-md-5">

                  <div className="mb-7">
                    <span className="text-cap">{ t('site:site_feature_cta_head') }</span>
                    <h3>{ t('site:site_feature_cta') }</h3>
                  </div>

                  <ul className="nav step step-icon-sm step-border-last-0"
                      id="step-TabFeatures">
                    <li className="step-item">

                      <span
                        onClick={() => setLine(1)}
                        className={`step-content-wrapper ${line === 1 ? 'active' : null }`}
                         tabIndex="-1">
                        <span className="step-icon step-icon-soft-secondary">1</span>

                        <div className="step-content">
                          <h6 className="step-title">
                          { t('site:site_feature_step1_title') }
                          </h6>
                          <p className="step-text">
                          { t('site:site_feature_step1_description') }
                          </p>
                        </div>
                      </span>
                    </li>

                    <li className="step-item" role="presentation">
                      <span
                        onClick={() => setLine(2)}
                        className={`step-content-wrapper ${line === 2 ? 'active' : null }`}
                        tabIndex="-1">
                      <span
                        className="step-icon step-icon-soft-secondary">2</span>
                        <div className="step-content">
                          <h6 className="step-title">
                          { t('site:site_feature_step2_title') }
                          </h6>
                          <p className="step-text">
                          { t('site:site_feature_step2_description') }
                          </p>
                        </div>
                      </span>
                    </li>

                    <li className="step-item" role="presentation">
                      <span
                        onClick={() => setLine(3)}
                        className={`step-content-wrapper ${line === 3 ? 'active' : null }`}
                      >
                      <span
                        className="step-icon step-icon-soft-secondary">3</span>
                        <div className="step-content">
                          <h6 className="step-title">
                          { t('site:site_feature_step3_title') }
                          </h6>
                          <p className="step-text">
                          { t('site:site_feature_step3_description') }
                          </p>
                        </div>
                      </span>
                    </li>


                    <li className="step-item" role="presentation">
                      <span
                        onClick={() => setLine(4)}
                        className={`step-content-wrapper ${line === 4 ? 'active' : null }`}
                      >
                      <span
                        className="step-icon step-icon-soft-secondary">4</span>
                        <div className="step-content">
                          <h6 className="step-title">
                          { t('site:site_feature_step4_title') }
                          </h6>
                          <p className="step-text">
                          { t('site:site_feature_step4_description') }
                          </p>
                        </div>
                      </span>
                    </li>
                  </ul>


                </div>
              </div>

            </div>

          </div>
        </div>



    </>
  );
}