import Logo from '../Components/Logo'
import { Link } from 'react-router-dom'
import { Button, Divider, Grid } from 'antd'
import AuthRegisterForm from './AuthRegisterForm'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import ReactGA from 'react-ga4'
import useDeviceType from '../Hooks/useDeviceType'
import SEO from '../Site/Components/SiteSEO'

const { useBreakpoint } = Grid;

const isMobile = (screens) => {
  if (Object.entries(screens).length === 0) {
    return false
  }

  return !screens.md
}

export default function AuthRegister () {
  const {t} = useTranslation(["signin"]);
  const screens = useBreakpoint();
  const { deviceType } = useDeviceType();

  useEffect(() => {
    const myPath = window.location.pathname + window.location.search;

    ReactGA.send({
      hitType: "pageview",
      page: myPath,
      title: "Register"
    });
  }, []);

  // todo: refactor this code
  if (isMobile(screens)) {
     return (
       <>
         <SEO
           title={'oggo.money - sign up now'}
           name={'oggo.money - register'}
           description={'Sign up for our easy budget planner! Link your bank, track expenses, and manage finances effortlessly. Start your journey to financial control now!'}
           type={'product'}
         />
         <div className="container mt-5">
           <div className="col-md-5 mb-7 mb-md-0">
             <div className="mb-7">
               <Logo isHomePage={false} linkHome={deviceType === 'webapp'}/>
             </div>
           </div>

           <div className="col-md-7 col-lg-6">
             { /* Form */}
             <div className="card">
               <div className="card-header bg-primary text-center">
                 <h4 className="card-header-title text-white">
                   { t("signin:adsTryFor15Days") }

                   { deviceType !== 'ios' ? (
                   <>
                     <br/>
                     <div
                       className="mt-2 badge bg-warning text-dark rounded-pill ms-1"
                       dangerouslySetInnerHTML={{ __html: t("signin:adsTextDescription") }} />
                   </>
                   ) : null}
                 </h4>
               </div>

               <div className="card-body">
                 <AuthRegisterForm/>

                 <Divider/>

                 <p> { t("signin:alreadyHaveAccount") }
                   <Link to="/auth/login"
                         className="text-primary text-gradient font-weight-bold"
                         >
                     <Button
                       block
                       style={{
                         paddingBottom: '34px',
                         paddingTop: '10px',
                         fontWeight: 700,
                         marginTop: '10px',
                       }}
                     >
                       { t("signin:btnSignIn") }</Button>
                   </Link>
                 </p>
               </div>
             </div>
           </div>
         </div>
       </>
     );
  }

  return (
    <>
      { /* Signup Form */}
      <div className="container content-space-2 content-space-lg-3">
        <div className="row justify-content-lg-between align-items-md-center">
          <div className="col-md-5 mb-7 mb-md-0">
            <div className="mb-5">
              <div className="mb-7">
                <Logo width={'9rem'} isHomePage={false} linkHome={deviceType === 'webapp'}/>
              </div>

              <h2>{ t("signin:headerTitle") }</h2>
              <p>{ t("signin:headerPhrase") }</p>
            </div>

            <h4>{ t("signin:headerCallText") }</h4>

            { /* List Checked */}
            <ul className="list-checked list-checked-primary">
              <li className="list-checked-item">
                { t("signin:text1") }
              </li>
              <li className="list-checked-item">
                { t("signin:text2") }
              </li>
              <li className="list-checked-item">
                { t("signin:text3") }
              </li>
              {/*<li className="list-checked-item">*/}
              {/*  <div dangerouslySetInnerHTML={{ __html: t("signin:text4") }} />*/}
              {/*</li>*/}
            </ul>
            { /* End List Checked */}
          </div>
          { /* End Col */}

          <div className="col-md-7 col-lg-6">
            { /* Form */}
            <div className="card">
              <div className="card-header bg-primary text-center">
                <h4 className="card-header-title text-white">
                  <div dangerouslySetInnerHTML={{ __html: t("signin:adsTryFor15Days")}} />
                  <div
                    className="mt-2 badge bg-warning text-dark rounded-pill ms-1"
                    dangerouslySetInnerHTML={{ __html: t("signin:adsTextDescription") }} />
                </h4>
              </div>

              <div className="card-body">
                <AuthRegisterForm/>
                <Divider/>

                <p> { t("signin:alreadyHaveAccount") }

                <Link to="/auth/login">
                 <Button
                   block
                   style={{
                     paddingBottom: '34px',
                     paddingTop: '10px',
                     fontWeight: 700,
                     marginTop: '10px',
                   }}
                   >
                   { t("signin:btnSignIn") }</Button>
                </Link>
                </p>

              </div>
            </div>
            { /* End Form */}
          </div>
          { /* End Col */}
        </div>
        { /* End Row */}
      </div>
      { /* End Signup Form */}
    </>
  )
}