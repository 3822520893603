import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import useLanguage from '../../Hooks/useLanguage'

export default function SiteLanguageSelector() {
  const [dropdown, setDropdown] = useState('');
  const {i18n} = useTranslation();
  const { saveLanguage } = useLanguage()

  const changeLanguage = (lng) => {
    saveLanguage(lng);
    window.location = '/'
    return;
  };

  return (
    <div className="btn-group" onClick={() => setDropdown(dropdown === '' ? 'show' : '')}>
      <button type="button"
              className="btn btn-soft-light btn-xs dropdown-toggle show"
              id="footerSelectLanguage" data-bs-toggle="dropdown"
              aria-expanded={dropdown ? "true" : "false"}
              data-bs-dropdown-animation="">
        Language
      </button>
      <div className={"dropdown-menu " + dropdown}
           data-bs-popper="none">

        {/*}
        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "zh" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("zh")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/cn.svg"
               alt="Personal finance manager" width="16"/>
          <span>中文 (繁體)</span>
        </Link>
*/}

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "de-DE" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("de-DE")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/de.svg"
               alt="Personal finance manager" width="16"/>
          <span>Deutsch</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "en" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("en")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/us.svg"
               alt="Personal finance manager" width="16"/>
          <span>English</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "es-ES" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("es-ES")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/es.svg"
               alt="Gerente de finanzas personales" width="16"/>
          <span>Español</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "fr-FR" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("fr-FR")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/fr.svg"
               alt="Gerente de finanzas personales" width="16"/>
          <span>Français</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "it-IT" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("it-IT")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/it.svg"
               alt="software di finanza personale" width="16"/>
          <span>Italiano</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "pt-PT" ? 'active' : '')}
              to="#" onClick={() => changeLanguage("pt-PT")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/pt.svg"
               alt="Sistema de gestão financeira pessoal" width="16"/>
          <span>Português (PT)</span>
        </Link>

        <Link className={"dropdown-item d-flex align-items-center " + (i18n.language === "pt-BR" ? 'active' : '')}
              to="/#" onClick={() => changeLanguage("pt-BR")}>
          <img className="avatar avatar-xss avatar-circle me-2"
               src="/vendor/flag-icon-css/flags/1x1/br.svg"
               alt="Sistema gestor financeiro" width="16"/>
          <span>Português (BR)</span>
        </Link>

      </div>
    </div>
  );
}