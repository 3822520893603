import { Fragment, useEffect, useState } from 'react'
import ReactGA from 'react-ga4';
import SiteHeader from './Components/SiteHeader'
import SiteFooter from './Components/SiteFooter'
import SitePricing from './SitePricing'
import { useTranslation } from 'react-i18next'
import useLanguage from '../Hooks/useLanguage'
import SiteCookies from './Components/SiteCookies'
import SEO from './Components/SiteSEO'
import SiteTestimonials from './SiteTestimonials'
import './css/site.home.css';
import SiteFaq from './SiteFaq'
import SiteCarousel from './Components/SiteCarousel'
import SiteFeaturesAccordion from './SiteFeaturesAccordion.js'
import SiteBannerC from './SiteBannerC'

export default function SiteHome () {
  const {i18n} = useTranslation()
  const { hasLanguage, saveLanguage, getLanguage } = useLanguage()

  let language = getLanguage()

  useEffect(() => {
    const myPath = window.location.pathname + window.location.search;
    ReactGA.send({
      hitType: "pageview",
      page: myPath,
      title: "Home"
    });

    const script = document.createElement('script');
    script.id = 'tidio';
    script.src = '//code.tidio.co/u4za5smwj8depv6s2ujqg3eku7pftxlz.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      try {
        document.getElementById('tidio').remove();
        document.getElementById('tidio-chat-iframe').remove();
        document.getElementById('tidio-chat-code').remove();
      } catch (e) {

      }
    }
  }, []);


  if (!hasLanguage()) {
    saveLanguage(language)
  }

  if (language !== i18n.language){
    i18n.changeLanguage(language);
  }

  const { t } = useTranslation(['site'])

  return (
    <Fragment>
      <SEO
        title={'oggo.money - personal finance made easy'}
        name={'oggo.money'}
        description={'Manage your accounts, track your expenses, and create a budget to achieve your goals and reduce debts with our intuitive platform. Easily integrate your bank account¹ to automatically import and categorize transactions.'}
        type={'product'}
      />


      <SiteHeader isHomePage={false}/>
        <main id="content" role="main">
          <SiteBannerC t={t} />
          <SiteCarousel t={t} />

          <SiteFeaturesAccordion t={t} />


          {/*<SiteBannerCards t={t}/>*/}


          {/*<SiteFeatures t={t}/>*/}
          <SiteTestimonials t={t} />
          <SitePricing t={t} language={language}/>
          <SiteFaq />
          {/*<SiteBannerFooter t={t}/>*/}
        </main>
      <SiteFooter t={t}/>
      <SiteCookies t={t} />
    </Fragment>
  )
}