import api from '../../Services/HttpService'
import ExceptionHandler from '../Services/ExceptionHandler'
import ReactGA from 'react-ga4'

const API_URL = 'categories'

const CategoriesRepository = {
  show: async (id, source) => {
    try {
      const response = await api.get(
        `${API_URL}/${id}`,
        {
          cancelToken: source.token,
        },
      )
      return response.data
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  },

  create: async (itemData) => {
    try {
      const response = await api.post(API_URL, itemData)

      ReactGA.event({
        category: "system_categories",
        action: "system_categories_created",
        label: "Categories > Created",
      });
      return response.data
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  },

  update: async (id, itemData) => {
    try {
      await api.patch(`${API_URL}/${id}`, itemData)

      ReactGA.event({
        category: "system_categories",
        action: "system_categories_updated",
        label: "Categories > Updated",
      });
      return true
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  },

  destroy: async (id) => {
    try {
      await api.delete(`${API_URL}/${id}`)

      ReactGA.event({
        category: "system_categories",
        action: "system_categories_deleted",
        label: "Categories > Deleted",
      });
      return true
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  },

  list: async (params, source) => {
    try {
      const response = await api.get(`${API_URL}?${params}`, {
        cancelToken: source.token,
      })
      return response.data
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  },

  icons: async (source) => {
    try {
      const response = await api.get(`${API_URL}-icons`, {
        cancelToken: source.token,
      })
      return response.data
    } catch (error) {
      ExceptionHandler.catch(error)
    }
  }
}

export default CategoriesRepository