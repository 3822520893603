import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { Suspense, lazy } from 'react'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { ConfigProvider } from 'antd'

import Backend from 'i18next-http-backend'

// error pages
import NotFound from './Components/NotFound'

// Layouts
import SiteLayout from './Site/SiteLayout'

// site
import SiteHome from './Site/SiteHome'

// Auth
import AuthLogin from './Auth/AuthLogin'
import AuthRegister from './Auth/AuthRegister'
import ProtectedRoutes from './Components/ProtectedRoutes'

/*
// system
import Dashboard from './System/Dashboard'
import SiteContact from './Site/SiteContact'
import AccountsList from './System/Accounts/AccountsList'
import DocumentsList from './System/Documents/DocumentsList'
import TagsList from './System/Tags/TagsList'
import DocumentsEdit from './System/Documents/DocumentsEdit'
import DocumentsCreate from './System/Documents/DocumentsCreate'
import TagsCreate from './System/Tags/TagsCreate'
import TagsEdit from './System/Tags/TagsEdit'
import PrepareData from './System/PrepareData/PrepareData'
import LoadingComponent from './System/Components/LoadingComponent'
import UserInvitesList from './System/UserInvites/UserInvitesList'
import UserInvitesCreate from './System/UserInvites/UserInvitesCreate'
import UserInvitesEdit from './System/UserInvites/UserInvitesEdit'
import SiteUserInviteAccept from './Site/SiteUserInviteAccept'
import AccountsCreate from './System/Accounts/AccountsCreate'
import AccountsEdit from './System/Accounts/AccountsEdit'
import ConnectAgreement from './System/Connect/ConnectAgreement'
import ConnectCallback from './System/Connect/ConnectCallback'
import SchedulesList from './System/Schedules/SchedulesList'
import SchedulesCreate from './System/Schedules/SchedulesCreate'
import SchedulesEdit from './System/Schedules/SchedulesEdit'
import TransactionsList from './System/Transactions/TransactionsList'
import TransactionsCreate from './System/Transactions/TransactionsCreate'
import TransactionsEdit from './System/Transactions/TransactionsEdit'

/*
 * Reports
 * /
import ReportsNetWorth from './System/Reports/ReportsNetWorth'
import ReportsPendingTransactions
  from './System/Reports/ReportsPendingTransactions'
import ReportsCategories from './System/Reports/ReportsCategories'
import ReportsStatement from './System/Reports/ReportsStatement'
import ReportsTags from './System/Reports/ReportsTags'
import BudgetPlannersList from './System/Budgets/BudgetPlannersList'
import BudgetPlannersCreate from './System/Budgets/BudgetPlannersCreate'
import BudgetPlannersEdit from './System/Budgets/BudgetPlannersEdit'
import ProfileIndex from './System/Profile/ProfileIndex'
import SiteThankYou from './Site/SiteThankYou'
import SubscriptionIndex from './System/Subscription/SubscriptionIndex'
import ReportsBudgetResults from './System/Reports/ReportsBudgetResults'
import AuthForgotPassword from './Auth/AuthForgotPassword'
import AuthPasswordReset from './Auth/AuthPasswordReset'
import AuthConfirmYourEmail from './Auth/AuthConfirmYourEmail'
*/


import ErrorBoundary from './Components/ErrorBoundary'
import SiteAbout from './Site/SiteAbout'
import ReactGA from "react-ga4";
import CategoriesList from './System/Categories/CategoriesList'
import CategoriesCreate from './System/Categories/CategoriesCreate'
import CategoriesEdit from './System/Categories/CategoriesEdit'
import { HelmetProvider } from 'react-helmet-async'

const SystemLayout = lazy(() => import('./System/SystemLayout'));
const SitePolicies = lazy(() => import('./Site/SitePolicies'));
const SitePoliciesTerms = lazy(() => import('./Site/SitePoliciesTerms'));
const SitePoliciesPrivacy = lazy(() => import('./Site/SitePoliciesPrivacy'));
const SitePoliciesCookies = lazy(() => import('./Site/SitePoliciesCookies'));
const SitePersonalFinanceWhatIs = lazy(() => import('./Site/PersonalFinance/SitePersonalFinanceWhatIs'));
const SitePersonalFinanceWhy = lazy(() => import('./Site/PersonalFinance/SitePersonalFinanceWhy'));
const SitePersonalFinanceWhen = lazy(() => import('./Site/PersonalFinance/SitePersonalFinanceWhen'));

// Lazy loaded components
const Dashboard = lazy(() => import('./System/Dashboard'));
const SiteFaq = lazy(() => import('./Site/SiteFaq'));
const SiteContact = lazy(() => import('./Site/SiteContact'));
const AccountsList = lazy(() => import('./System/Accounts/AccountsList'));
const DocumentsList = lazy(() => import('./System/Documents/DocumentsList'));
const TagsList = lazy(() => import('./System/Tags/TagsList'));
const DocumentsEdit = lazy(() => import('./System/Documents/DocumentsEdit'));
const DocumentsCreate = lazy(() => import('./System/Documents/DocumentsCreate'));
const TagsCreate = lazy(() => import('./System/Tags/TagsCreate'));
const TagsEdit = lazy(() => import('./System/Tags/TagsEdit'));
const PrepareData = lazy(() => import('./System/PrepareData/PrepareData'));
const LoadingComponent = lazy(() => import('./System/Components/LoadingComponent'));
const UserInvitesList = lazy(() => import('./System/UserInvites/UserInvitesList'));
const UserInvitesCreate = lazy(() => import('./System/UserInvites/UserInvitesCreate'));
const UserInvitesEdit = lazy(() => import('./System/UserInvites/UserInvitesEdit'));
const SiteUserInviteAccept = lazy(() => import('./Site/SiteUserInviteAccept'));
const AccountsCreate = lazy(() => import('./System/Accounts/AccountsCreate'));
const AccountsEdit = lazy(() => import('./System/Accounts/AccountsEdit'));
const ConnectAgreement = lazy(() => import('./System/Connect/ConnectAgreement'));
const ConnectCallback = lazy(() => import('./System/Connect/ConnectCallback'));
const SchedulesList = lazy(() => import('./System/Schedules/SchedulesList'));
const SchedulesCreate = lazy(() => import('./System/Schedules/SchedulesCreate'));
const SchedulesEdit = lazy(() => import('./System/Schedules/SchedulesEdit'));
const TransactionsList = lazy(() => import('./System/Transactions/TransactionsList'));
const TransactionsCreate = lazy(() => import('./System/Transactions/TransactionsCreate'));
const TransactionsEdit = lazy(() => import('./System/Transactions/TransactionsEdit'));
const ReportsNetWorth = lazy(() => import('./System/Reports/ReportsNetWorth'));
const ReportsPendingTransactions = lazy(() => import('./System/Reports/ReportsPendingTransactions'));
const ReportsCategories = lazy(() => import('./System/Reports/ReportsCategories'));
const ReportsStatement = lazy(() => import('./System/Reports/ReportsStatement'));
const ReportsTags = lazy(() => import('./System/Reports/ReportsTags'));
const BudgetsList = lazy(() => import('./System/Budgets/BudgetsList'));
const BudgetsPreCreate = lazy(() => import('./System/Budgets/BudgetsPreCreate'));
const BudgetsAICreate = lazy(() => import('./System/Budgets/BudgetsAICreate'));
const BudgetsCreate = lazy(() => import('./System/Budgets/BudgetsCreate'));
const BudgetsEdit = lazy(() => import('./System/Budgets/BudgetsEdit'));
const AuthLogout = lazy(() => import('./Auth/AuthLogout'));
const ProfileIndex = lazy(() => import('./System/Profile/ProfileIndex'));
const SiteThankYou = lazy(() => import('./Site/SiteThankYou'));
const SubscriptionIndex = lazy(() => import('./System/Subscription/SubscriptionIndex'));
const ReportsBudgets = lazy(() => import('./System/Reports/ReportsBudgets'));
const ReportsBudgetResults = lazy(() => import('./System/Reports/ReportsBudgetResults'));
const AuthForgotPassword = lazy(() => import('./Auth/AuthForgotPassword'));
const AuthPasswordReset = lazy(() => import('./Auth/AuthPasswordReset'));
const AuthConfirmYourEmail = lazy(() => import('./Auth/AuthConfirmYourEmail'));

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  ReactGA.initialize('G-NKXDSMB5SV');
} else {
  ReactGA.initialize('G-B5VXTJ79P9'); // prod
}

i18n.use(Backend).use(initReactI18next).init({
  fallbackLng: localStorage.getItem('language') || 'en',
  debug: false,
  interpolation: {
    escapeValue: false,
  },
})

const ErrorTest = () => {
  throw new Error('An error occurred');
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" errorElement={<ErrorBoundary />}>

      <Route path={"7test-error-page"}  element={<ErrorTest />}/>

      { /* Site area */}
      <Route element={<SiteLayout/>}>
        <Route index element={<SiteHome/>}/>
        <Route path="policies" element={<SitePolicies/>}>
          <Route path="cookies" element={<SitePoliciesCookies/>}/>
          <Route path="terms" element={<SitePoliciesTerms/>}/>
          <Route path="privacy" element={<SitePoliciesPrivacy/>}/>
        </Route>

        <Route path="invite/:code" element={<SiteUserInviteAccept/>}/>

        <Route path="contact" element={<SiteContact/>}/>
        <Route path="faq" element={<SiteFaq />} />
        <Route path="about" element={<SiteAbout/>}/>
        <Route path="thank-you" element={<SiteThankYou />}/>
        <Route path="what-is-personal-finance" element={<SitePersonalFinanceWhatIs />}/>
        <Route path="why-personal-finance-is-important" element={<SitePersonalFinanceWhy />}/>
        <Route path="when-should-you-start-planning-your-personal-finances" element={<SitePersonalFinanceWhen/>}/>
      </Route>

      { /* Auth area */}
      <Route path="/auth">
        <Route path="login" element={<AuthLogin/>}/>
        <Route path="logout" element={<AuthLogout/>}/>
        <Route path="register" element={<AuthRegister/>}/>
        <Route path="forgot-password" element={<AuthForgotPassword/>}/>
        <Route path="password-reset/:tokenId" element={<AuthPasswordReset/>}/>
        <Route path={"confirm-your-email"} element={<AuthConfirmYourEmail />} />
      </Route>

      { /* System area */}
      <Route element={<ProtectedRoutes/>}>
        <Route path="system/preparing" element={<PrepareData/>}/>
        <Route path="system" element={<SystemLayout/>}>
          <Route index element={<Dashboard/>}/>
          <Route path="profile" element={<ProfileIndex/>}/>
          <Route path="subscription" element={<SubscriptionIndex/>}/>

          <Route path="accounts" element={<AccountsList/>}/>
          <Route path="accounts/create" element={<AccountsCreate/>}/>
          <Route path="accounts/:accountId" element={<AccountsEdit/>}/>

          <Route path="budgets" element={<BudgetsList/>}/>
          <Route path="budgets/pre-create" element={<BudgetsPreCreate />}/>
          <Route path="budgets/ai-create" element={<BudgetsAICreate />}/>
          <Route path="budgets/create" element={<BudgetsCreate/>}/>
          <Route path="budgets/:budgetId" element={<BudgetsEdit/>}/>

          <Route path="schedules" element={<SchedulesList/>}/>
          <Route path="schedules/create" element={<SchedulesCreate />}/>
          <Route path="schedules/:scheduleId" element={<SchedulesEdit/>}/>

          <Route path="transactions" element={<TransactionsList/>}/>
          <Route path="transactions/create" element={<TransactionsCreate />}/>
          <Route path="transactions/:transactionId" element={<TransactionsEdit/>}/>

          <Route path="categories" element={<CategoriesList/>}/>
          <Route path="categories/create" element={<CategoriesCreate />}/>
          <Route path="categories/create/:parentId" element={<CategoriesCreate />}/>
          <Route path="categories/:categoryId/edit" element={<CategoriesEdit/>}/>

          <Route path="documents" element={<DocumentsList/>}/>
          <Route path="documents/create"
                 element={<DocumentsCreate
                   title={'Documents'}
                   endpoint={'documents'}/>}
          />
          <Route path="documents/:documentId/edit" element={<DocumentsEdit/>}/>

          <Route path="tags" element={<TagsList/>}/>
          <Route path="tags/create" element={<TagsCreate/>}/>
          <Route path="tags/:tagId/edit" element={<TagsEdit/>}/>

          <Route path="user-invites" element={<UserInvitesList/>}/>
          <Route path="user-invites/create" element={<UserInvitesCreate/>}/>
          <Route path="user-invites/:userInviteId/edit"
                 element={<UserInvitesEdit/>}/>


          <Route path="connect/:service/agreements/:institutionId"
                 element={<ConnectAgreement/>}/>

          <Route path="connect/callback"
                 element={<ConnectCallback/>}/>

          <Route path="reports/net-worth"
                 element={<ReportsNetWorth/>}/>

          <Route path="reports/pending-transactions"
                 element={<ReportsPendingTransactions/>}/>

          <Route path="reports/categories"
                 element={<ReportsCategories/>}/>

          <Route path="reports/statement"
                 element={<ReportsStatement/>}/>

          <Route path="reports/budgets"
                 element={<ReportsBudgets />}/>

          <Route path="reports/budgets-results"
                 element={<ReportsBudgetResults/>}/>

          <Route path="reports/tags"
                 element={<ReportsTags/>}/>
        </Route>
      </Route>

      <Route path="*" element={<NotFound/>}/>
    </Route>
    ,
  ),
)

function App () {
  const helmetContext = {};

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimaryTextActive: '#ffffff',
          colorPrimary: '#fc0391',
          fontSize: 15,
          lineHeightSM: 9.666666666666666,
          sizeStep: 5,
          sizeUnit: 4,
          paddingXXS: 8,
          paddingSM: 10,
          padding: 18,
          paddingMD: 18,
          boxShadow: '0 ',
          paddingContentHorizontal: 15,
        },
      }}
    >
      <Suspense fallback={<LoadingComponent/>}>
        <HelmetProvider context={helmetContext}>
          <RouterProvider router={router}/>
        </HelmetProvider>
      </Suspense>
    </ConfigProvider>
  )
}

export default App
